import React, { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import './styles/home.css';
import Item from '../component/Item';
import { useDispatch, useSelector } from 'react-redux';
import { fetchItems } from '../redux/actions/itemActions';
import Loder from '../component/Loder';
import ProductsView from '../component/ProductsView';
import ImagesCollage from '../component/ImagesCollage';
import { useNavigate } from 'react-router-dom';
import { AiOutlineSearch } from 'react-icons/ai';

function Home() {

    const [isFixed, setIsFixed] = useState(false);
    const [search, setSearch] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [searchedItems, setSearchedItems] = useState([]);
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const state = useSelector((state) => state);
    console.log(state);

    const { loading, items, error } = state.itemsData;

    useEffect(() => {
        dispatch(fetchItems());
        console.log(items);
    }, [dispatch]);

    const handleSearch = () => {
        setSearchTerm(inputValue);
        const filteredItems = items.filter(item =>
            item.name.toLowerCase().includes(inputValue.toLowerCase())
        );
        setSearch(true);
        setSearchedItems(filteredItems);
        setIsFixed(true);

    };

    const handleSearchClick = (e) => {
        e.preventDefault();
        handleSearch();
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSearch();
        }
    };

    useEffect(() => {
        const element = document.querySelector('.text-to-animate');
        element.classList.add('typing-effect');
    }, []);

    const navigateToBom = (product_id) => {
        console.log(product_id);

        navigate(`/bom/${product_id}`);
    }

    function detailedSearchItem(item) {

        // const images = [
        //     "https://teakruthi.com/cdn/shop/articles/green_grande.jpg?v=1511375351",
        //     "https://ceylonteainfusions.com/wp-content/uploads/2020/09/green-tea.jpg",
        //     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQJielt9ZMMg5BN35O7S20JPth3NNbs8HugD6I8Axa5cs6SxT4udw-xs08mBl-vsP_lYkI&usqp=CAU"
        // ]

        return (
            <div className='d-flex justify-content-center align-items-center'>
                <div className="detailed-card container">
                    <div className='image-area'>
                        <div className='img1Cover'>
                            <img src={item.image1_url}
                                className='imgc1' alt="Image 1" />
                        </div>
                        <div className='d-flex'>
                            <div className='img2Cover'>
                                <img src={item.image2_url} className='imgc2' alt="Image 2" />
                            </div>
                            <div className='img3Cover'>
                                <img src={item.image3_url} className='imgc3' alt="Image 3" />
                            </div>
                        </div>
                    </div>
                    <div className='divider'></div>
                    <div className="details">
                        <h1 className="item-title text-left">{item.name}</h1>
                        <p className="item-description">{item.description}</p>
                        <p className="item-price">{item.price}</p>
                        <button className="go-to " onClick={() => { navigateToBom(item.product_id) }}>Go to</button>
                    </div>
                </div>
            </div>

        );
    }

    return (
        <>
            <div className='h-100'>
                <div className='d-flex  justify-content-center align-items-center'>
                    <div>
                        <div className={`search-group ${isFixed ? 'fixed-search ' : ''}`}>
                            <div className={`${isFixed ? 'text-center my-2 opacity-0' : 'text-center my-2 opacity-75'}`}>
                                <p className='text-to-animate text-center my-2 opacity-50'>To keep the  Green And Clean</p>
                            </div>
                            <div className={`${isFixed ? 'navigate-search-box' : ''}`}>
                                <div className={`d-flex justify-content-center align-item-center`}>
                                    <div>
                                        <form onSubmit={handleSearchClick}>
                                            <input
                                                type="text"
                                                placeholder="Search..."
                                                className={`${isFixed ? 'searchBox-text-box-searched' : 'searchBox-text-box'}`}
                                                value={inputValue}
                                                onChange={(e) => setInputValue(e.target.value)}
                                                onKeyDown={handleKeyDown}  // Added for "Enter" press
                                            />
                                        </form>
                                    </div>
                                    <div className={`${isFixed ? 'searchBox-bg-searched' : 'searchBox-bg'}`} onClick={handleSearchClick}>
                                        <FaSearch className='icon' />
                                    </div>


                                </div>
                            </div>
                            {searchTerm && search ? (
                                <div className='text-center'>
                                    <p className='searched-text my-4'>
                                        Results For <b>{searchTerm}</b>
                                    </p>
                                </div>
                            ) : null}

                            <div className='searched-cards'>
                                {loading ? (
                                    <div className='d-flex justify-content-center align-items-center mt-5'>
                                        {/* <Loder /> */}
                                    </div>
                                ) : (
                                    <>
                                        {searchTerm.trim() !== "" && searchedItems.length > 0 ? (
                                            <div>
                                                {/* Render detailedSearchItem only for the first item */}
                                                {searchedItems.length > 0 && (
                                                    <div className='d-flex justify-content-center align-items-center'>
                                                        {detailedSearchItem(searchedItems[0])}
                                                    </div>
                                                )}

                                                {/* Search items below detailedSearchItem, displayed in two columns */}
                                                <div className='d-flex flex-wrap container'>
                                                    {searchedItems.slice(1).map((item, index) => (
                                                        <div key={index} className='item-card'>
                                                            <Item item={item} />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ) : searchedItems.length === 0 && searchTerm ? (
                                            <p className={`text-center ${isFixed ? '' : 'display-none'}`}>No results found</p>
                                        ) : null}
                                    </>
                                )}
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <div className='products-view container mt-5'>
                {searchTerm.trim() !== "" && searchedItems.length > 0 ? (
                    <><h4>GreenBless Products</h4><hr /></>
                ) : (<></>)

                }
                <ProductsView />
            </div>
        </>
    );
}

export default Home;

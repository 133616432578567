import React, { useState } from 'react';
import { Container, Row, Col, Card, ListGroup, Button, Form, Badge,Image } from 'react-bootstrap';
import { FaUser } from 'react-icons/fa';
import './styles/PackageSelect.css'; // Add custom styles if needed.
import { useParams } from 'react-router-dom';
import { postedPackages } from '../../dummy/postedPackages'; // Import your dummy data.

function PackageSelect() {
    const { packageId } = useParams();
    const selectedPackage = postedPackages.find(pkg => pkg.id === parseInt(packageId));

    const [bidAmount, setBidAmount] = useState('');
    const [bids, setBids] = useState([]);
    const [comment, setComment] = useState('');
    const [comments, setComments] = useState([]);
    

    const handleBidSubmit = (e) => {
        e.preventDefault();
        if (bidAmount) {
            const avatarUrl = 'https://www.w3schools.com/w3images/avatar2.png';
            setBids([...bids, { amount: bidAmount, name: 'Your Name',avatarUrl }]); // Replace with actual user data.
            setBidAmount('');
          
        }
    };

    const handleCommentSubmit = (e) => {
        e.preventDefault();
        if (comment) {
            setComments([
                ...comments,
                { text: comment, user: 'Your Name', avatar: 'https://www.w3schools.com/w3images/avatar2.png' } // Add user avatar URL
            ]); 
            setComment('');
            
        }
    };

    if (!selectedPackage) {
        return <Container className="my-5 text-center">Package not found!</Container>;
    }

    return (
        <Container className="my-5">
            <Row>
                {/* Package Details Section */}
                <Col md={8}>
                    <Card className="shadow mb-4">
                        <Card.Img variant="top" src={selectedPackage.image} alt={selectedPackage.title} />
                        <Card.Body>
                            <Card.Title className="mb-3">{selectedPackage.title}</Card.Title>
                            <Card.Text>
                                <strong>Destination:</strong> {selectedPackage.destination} <br />
                                <strong>Price:</strong> ${selectedPackage.price} <br />
                                <strong>Bids:</strong> {selectedPackage.bids} <br />
                                <strong>Description:</strong> {selectedPackage.description}
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <div className="d-flex align-items-center">
                                <img
                                    src={selectedPackage.user.avatar}
                                    alt={selectedPackage.user.name}
                                    className="rounded-circle me-2"
                                    style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                                />
                                <span>
                                    <FaUser className="me-2" />
                                    Posted by {selectedPackage.user.name}
                                </span>
                            </div>
                        </Card.Footer>
                    </Card>
                </Col>

                {/* Bidding Section */}
                <Col md={4}>
                    <Card className="shadow">
                        <Card.Body>
                            <Card.Title className="mb-3">Place Your Bid</Card.Title>
                            <Form onSubmit={handleBidSubmit}>
                                <Form.Group className="mb-3" controlId="bidAmount">
                                    <Form.Label>Bid Amount ($)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Enter your bid"
                                        value={bidAmount}
                                        onChange={(e) => setBidAmount(e.target.value)}
                                        required
                                    />
                                </Form.Group>
                                <Button variant="success" type="submit" className="w-100">
                                    Place Bid
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>

                    {/* Current Bids */}
                    {bids.length > 0 && (
                        <Card className="shadow mt-4">
                            <Card.Body>
                                <Card.Title className="mb-3">Current Bids</Card.Title>
                                <ListGroup>
                                    {bids.map((bid, idx) => (
                                        <ListGroup.Item key={idx} className="d-flex justify-content-between align-items-center">

                                    <div className="d-flex align-items-center">
                                             <Image
                                            src={bid.avatarUrl} // Use avatarUrl from the bid object
                                            roundedCircle
                                            width={40}
                                            height={40}
                                            className="me-2 border border-secondary"
                                            alt={bid.name}
                                        />
                                            <span>{bid.name}</span>
                                            </div>
                                            <Badge bg="primary">${bid.amount}</Badge>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </Card.Body>
                        </Card>
                    )}
                </Col>
            </Row>

            {/* Comment Section */}
            <Row className="mt-5">
                <Col md={8}>
                    <Card className="shadow">
                        <Card.Body>
                            <Card.Title className="mb-3">Comments</Card.Title>
                            <Form onSubmit={handleCommentSubmit}>
                                <Form.Group className="mb-3" controlId="comment">
                                    <Form.Label>Your Comment</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        value={comment}
                                        onChange={(e) => setComment(e.target.value)}
                                        placeholder="Share your thoughts about this package"
                                        required
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit" className="w-100">
                                    Post Comment
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>

                    {/* Display Comments */}
                    {comments.length > 0 && (
                        <Card className="shadow mt-4">
                            <Card.Body>
                                <ListGroup>
                                    {comments.map((comment, idx) => (
                                        <ListGroup.Item key={idx} className="d-flex align-items-center">
                                            <img
                                                src={comment.avatar}
                                                alt={comment.user}
                                                className="rounded-circle me-3"
                                                style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                                            />
                                            <div>
                                                <strong>{comment.user}</strong>
                                                <p>{comment.text}</p>
                                            </div>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </Card.Body>
                        </Card>
                    )}
                </Col>
            </Row>
        </Container>
    );
}

export default PackageSelect;

import React, { useState } from "react";
import { Container, Form, Button, Table, Dropdown, Modal } from "react-bootstrap";
import { FaCogs, FaEdit, FaTrashAlt, FaCalendarAlt } from "react-icons/fa";

function ResourceGroup() {
    const [groupName, setGroupName] = useState("");
    const [category, setCategory] = useState("Select Category");
    const [description, setDescription] = useState("");
    const [site, setSite] = useState("");
    const [groups, setGroups] = useState([]);
    const [editingGroup, setEditingGroup] = useState(null);

    const categories = ["Farming", "Machinery", "Irrigation", "Storage"];

    const handleAddGroup = (e) => {
        e.preventDefault();
        if (groupName && category !== "Select Category") {
            const newGroup = {
                id: groups.length + 1,
                name: groupName,
                category,
                description,
                site,
            };
            setGroups([...groups, newGroup]);
            setGroupName("");
            setCategory("Select Category");
            setDescription("");
            setSite("");
        }
    };

    const handleEditGroup = (group) => {
        setEditingGroup(group);
        setGroupName(group.name);
        setCategory(group.category);
        setDescription(group.description);
        setSite(group.site);
    };

    const handleUpdateGroup = (e) => {
        e.preventDefault();
        const updatedGroups = groups.map((group) =>
            group.id === editingGroup.id
                ? { ...group, name: groupName, category, description, site }
                : group
        );
        setGroups(updatedGroups);
        resetForm();
    };

    const handleDeleteGroup = (id) => {
        setGroups(groups.filter((group) => group.id !== id));
    };

    const resetForm = () => {
        setEditingGroup(null);
        setGroupName("");
        setCategory("Select Category");
        setDescription("");
        setSite("");
    };

    return (
        <Container fluid className="p-0">
            {/* Hero Section */}
            <div
                style={{
                    backgroundImage: "url('https://eu-images.contentstack.com/v3/assets/bltdd43779342bd9107/blt1645b96b020bdced/64380d5d8d2c523d0da7467d/543212762.jpg')",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    color: "#fff",
                    padding: "4rem 2rem",
                    textAlign: "center",
                    height: "300px",
                }}
            >
                <div
                    style={{
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        height: "100%",
                        width: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0,
                    }}
                ></div>
                <div style={{ position: "relative" }}>
                    <h1 className="display-4">Resource Group Management</h1>
                    <p className="lead">Efficiently manage and organize your resources.</p>
                </div>
            </div>

            {/* Main Content */}
            <Container className="py-4">
                <h2 className="text-success">Manage Resource Groups</h2>
                <Form onSubmit={editingGroup ? handleUpdateGroup : handleAddGroup} className="mb-4">
                    <Form.Group className="mb-3" controlId="groupName">
                        <Form.Label>Group Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter group name"
                            value={groupName}
                            onChange={(e) => setGroupName(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="category">
                        <Form.Label>Category</Form.Label>
                        <Dropdown>
                            <Dropdown.Toggle variant="light">{category}</Dropdown.Toggle>
                            <Dropdown.Menu>
                                {categories.map((cat, index) => (
                                    <Dropdown.Item key={index} onClick={() => setCategory(cat)}>
                                        {cat}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </Dropdown>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="description">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Enter description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="site">
                        <Form.Label>Site</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter site name"
                            value={site}
                            onChange={(e) => setSite(e.target.value)}
                        />
                    </Form.Group>

                    <Button variant="success" type="submit">
                        {editingGroup ? "Update Group" : "Add Group"}
                    </Button>
                    {editingGroup && (
                        <Button variant="secondary" onClick={resetForm} className="ms-2">
                            Cancel
                        </Button>
                    )}
                </Form>

                {/* Groups Table */}
                {groups.length > 0 ? (
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Group Name</th>
                                <th>Category</th>
                                <th>Description</th>
                                <th>Site</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groups.map((group) => (
                                <tr key={group.id}>
                                    <td>{group.id}</td>
                                    <td>{group.name}</td>
                                    <td>{group.category}</td>
                                    <td>{group.description}</td>
                                    <td>{group.site}</td>
                                    <td>
                                        <Button
                                            variant="warning"
                                            size="sm"
                                            onClick={() => handleEditGroup(group)}
                                            className="me-2"
                                        >
                                            <FaEdit />
                                        </Button>
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            onClick={() => handleDeleteGroup(group.id)}
                                        >
                                            <FaTrashAlt />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                ) : (
                    <p className="text-center text-muted">No groups created yet.</p>
                )}
            </Container>
        </Container>
    );
}

export default ResourceGroup;

import React from 'react'
import { Container, Card, Row, Col, Button, InputGroup, FormControl } from 'react-bootstrap';
import { FaStar } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';


function TourSlider({ vales }) {

    const handleGuidesRatings = (guid) => {
        const rating = guid.rating;
        const fullStars = Array(Math.floor(rating)).fill(true);
        console.log("fullStars", fullStars);
        const emptyStars = Array(5 - Math.floor(rating)).fill(false);
        console.log("emptyStars", emptyStars);
        return (
            <>
                <div>
                    {fullStars.map((_, index) => (
                        <FaStar key={index} className='star filled' />
                    ))}
                    {emptyStars.map((_, index) => (
                        <FaStar key={index} className='star empty' />
                    ))}
                </div>
            </>
        )
    }

    // var settings = {
    //     dots: true,
    //     navigator:true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 4,
    //     slidesToScroll: 4,
    //     initialSlide: 0,
    //     responsive: [
    //         {
    //             breakpoint: 1024,
    //             settings: {
    //                 slidesToShow: 3,
    //                 slidesToScroll: 3,
    //                 infinite: true,
    //                 dots: true
    //             }
    //         },
    //         {
    //             breakpoint: 600,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 2,
    //                 initialSlide: 2
    //             }
    //         },
    //         {
    //             breakpoint: 480,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1
    //             }
    //         }
    //     ]
    // };

    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 2,
        speed: 100,
        autoplay: true, // Enable autoplay
        autoplaySpeed: 3000, // Set the autoplay speed (in milliseconds)
        pauseOnHover: true, // Pause autoplay on hover
    };

    return (
        <Slider {...settings} className='my-4 '>

            {vales ? (
                vales.map((item, index) => (
                    <div>
                        <Card key={item.id} className="p-3 shadow-sm h-100 m-2">
                            <div className="d-flex align-items-center">
                                <div className="me-3">
                                    <img
                                        src={item.image}
                                        alt={`${item.name}'s profile`}
                                        className="rounded-circle"
                                        style={{
                                            width: '80px',
                                            height: '80px',
                                            objectFit: 'cover',
                                            border: '2px solid #28a745',
                                        }}
                                    />
                                </div>
                                <div>
                                    <h5 className="mb-1">{item.name}</h5>
                                    <p className="text-muted mb-1">{item.location}</p>
                                </div>
                            </div>
                            <Card.Body className="px-0">
                                <Card.Text>{item.description}</Card.Text>
                                <Card.Text>{handleGuidesRatings(item)}</Card.Text>
                            </Card.Body>
                            <Card.Footer className="bg-white px-0">
                                <Link to={`/userProfile/${item.id}`}><Button variant="success">View Profile</Button></Link>
                            </Card.Footer>
                        </Card>
                    </div>
                ))
            ) : (
                <p>No guides available.</p>
            )}

        </Slider>
    )
}

export default TourSlider
export const tourGuides = [
    {
        id: 1,
        name: "John Doe",
        location: "Paris, France",
        description: "Experienced guide for cultural and historical tours.",
        image: "https://tourscoop.com/wp-content/uploads/2023/03/EF-Ultimate-Break-tours-are-vacations-for-18-to-35-year-olds-Photo-EF-Education-First.jpg",
        rating: 4
    },
    {
        id: 2,
        name: "Jane Smith",
        location: "Rome, Italy",
        description: "Specialist in ancient ruins and architecture.",
        image: "https://tourscoop.com/wp-content/uploads/2023/03/EF-Ultimate-Break-tours-are-vacations-for-18-to-35-year-olds-Photo-EF-Education-First.jpg",
        rating: 5
    },
    {
        id: 3,
        name: "Ali Khan",
        location: "Istanbul, Turkey",
        description: "Expert in local cuisine and hidden gems.",
        image: "https://tourscoop.com/wp-content/uploads/2023/03/EF-Ultimate-Break-tours-are-vacations-for-18-to-35-year-olds-Photo-EF-Education-First.jpg",
        rating: 3
    },
    {
        id: 4,
        name: "Maria Gonzalez",
        location: "Barcelona, Spain",
        description: "Passionate about Gaudi's works and local traditions.",
        image: "https://tourscoop.com/wp-content/uploads/2023/03/EF-Ultimate-Break-tours-are-vacations-for-18-to-35-year-olds-Photo-EF-Education-First.jpg",
        rating: 4
    },
    {
        id: 5,
        name: "David Zhang",
        location: "Beijing, China",
        description: "Knowledgeable about the Forbidden City and Great Wall.",
        image: "https://tourscoop.com/wp-content/uploads/2023/03/EF-Ultimate-Break-tours-are-vacations-for-18-to-35-year-olds-Photo-EF-Education-First.jpg",
        rating: 5
    },
    {
        id: 6,
        name: "Fatima Ahmed",
        location: "Cairo, Egypt",
        description: "Specialist in ancient Egyptian history and pyramids.",
        image: "https://tourscoop.com/wp-content/uploads/2023/03/EF-Ultimate-Break-tours-are-vacations-for-18-to-35-year-olds-Photo-EF-Education-First.jpg",
        rating: 4
    },
    {
        id: 7,
        name: "William Brown",
        location: "New York, USA",
        description: "Enthusiastic about modern architecture and cultural spots.",
        image: "https://tourscoop.com/wp-content/uploads/2023/03/EF-Ultimate-Break-tours-are-vacations-for-18-to-35-year-olds-Photo-EF-Education-First.jpg",
        rating: 3
    },
    {
        id: 8,
        name: "Sophia Rossi",
        location: "Venice, Italy",
        description: "Expert in Venetian canals and Renaissance art.",
        image: "https://tourscoop.com/wp-content/uploads/2023/03/EF-Ultimate-Break-tours-are-vacations-for-18-to-35-year-olds-Photo-EF-Education-First.jpg",
        rating: 5
    },
    {
        id: 9,
        name: "Liam O'Connor",
        location: "Dublin, Ireland",
        description: "Fascinated by Irish folklore and historical landmarks.",
        image: "https://tourscoop.com/wp-content/uploads/2023/03/EF-Ultimate-Break-tours-are-vacations-for-18-to-35-year-olds-Photo-EF-Education-First.jpg",
        rating: 4
    },
    {
        id: 10,
        name: "Amara Ndlovu",
        location: "Cape Town, South Africa",
        description: "Skilled at wildlife tours and coastal excursions.",
        image: "https://tourscoop.com/wp-content/uploads/2023/03/EF-Ultimate-Break-tours-are-vacations-for-18-to-35-year-olds-Photo-EF-Education-First.jpg",
        rating: 5
    },
    {
        id: 11,
        name: "Takeshi Tanaka",
        location: "Kyoto, Japan",
        description: "Passionate about Zen gardens and Japanese traditions.",
        image: "https://tourscoop.com/wp-content/uploads/2023/03/EF-Ultimate-Break-tours-are-vacations-for-18-to-35-year-olds-Photo-EF-Education-First.jpg",
        rating: 4
    },
    {
        id: 12,
        name: "Emma Wilson",
        location: "Sydney, Australia",
        description: "Specialist in iconic landmarks and beaches.",
        image: "https://tourscoop.com/wp-content/uploads/2023/03/EF-Ultimate-Break-tours-are-vacations-for-18-to-35-year-olds-Photo-EF-Education-First.jpg",
        rating: 5
    },
    {
        id: 13,
        name: "Juan Carlos",
        location: "Buenos Aires, Argentina",
        description: "Expert in tango culture and local cuisine.",
        image: "https://tourscoop.com/wp-content/uploads/2023/03/EF-Ultimate-Break-tours-are-vacations-for-18-to-35-year-olds-Photo-EF-Education-First.jpg",
        rating: 3
    },
    {
        id: 14,
        name: "Anna Petrova",
        location: "Moscow, Russia",
        description: "Specialist in Kremlin tours and Russian history.",
        image: "https://tourscoop.com/wp-content/uploads/2023/03/EF-Ultimate-Break-tours-are-vacations-for-18-to-35-year-olds-Photo-EF-Education-First.jpg",
        rating: 4
    },
    {
        id: 15,
        name: "Ahmed Al-Mahmoud",
        location: "Dubai, UAE",
        description: "Guide for futuristic architecture and desert safaris.",
        image: "https://tourscoop.com/wp-content/uploads/2023/03/EF-Ultimate-Break-tours-are-vacations-for-18-to-35-year-olds-Photo-EF-Education-First.jpg",
        rating: 4
    },
    {
        id: 16,
        name: "Isabella Chavez",
        location: "Mexico City, Mexico",
        description: "Specialist in Aztec ruins and cultural traditions.",
        image: "https://tourscoop.com/wp-content/uploads/2023/03/EF-Ultimate-Break-tours-are-vacations-for-18-to-35-year-olds-Photo-EF-Education-First.jpg",
        rating: 5
    },
    {
        id: 17,
        name: "Oliver Berg",
        location: "Berlin, Germany",
        description: "Expert in WWII landmarks and modern art scenes.",
        image: "https://tourscoop.com/wp-content/uploads/2023/03/EF-Ultimate-Break-tours-are-vacations-for-18-to-35-year-olds-Photo-EF-Education-First.jpg",
        rating: 4
    },
    {
        id: 18,
        name: "Leila Karim",
        location: "Marrakech, Morocco",
        description: "Knowledgeable about souks and Moroccan traditions.",
        image: "https://tourscoop.com/wp-content/uploads/2023/03/EF-Ultimate-Break-tours-are-vacations-for-18-to-35-year-olds-Photo-EF-Education-First.jpg",
        rating: 5
    },
    {
        id: 19,
        name: "Noah Johnson",
        location: "Toronto, Canada",
        description: "Specialist in city tours and natural wonders.",
        image: "https://tourscoop.com/wp-content/uploads/2023/03/EF-Ultimate-Break-tours-are-vacations-for-18-to-35-year-olds-Photo-EF-Education-First.jpg",
        rating: 3
    },
    {
        id: 20,
        name: "Chloe Lee",
        location: "Seoul, South Korea",
        description: "Passionate about K-pop, fashion, and cultural landmarks.",
        image: "https://tourscoop.com/wp-content/uploads/2023/03/EF-Ultimate-Break-tours-are-vacations-for-18-to-35-year-olds-Photo-EF-Education-First.jpg",
        rating: 4
    }
];

import React from 'react';

function ViewButton({name}) {
  return (
    <div className="d-flex justify-content-center align-items-center" >
      <button
        className="shine-button"
        style={{
          background: 'transparent',
          border: '2px solid #28a745',
          color: '#05ff82',
          padding: '10px 20px',
          borderRadius: '50px',
          fontSize: '1rem',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          letterSpacing: '1px',
          position: 'relative',
          overflow: 'hidden',
          transition: '0.3s ease-in-out',
          cursor: 'pointer',
        }}
      >
        {name}
        <span
          className="shine-effect"
          style={{
            position: 'absolute',
            top: '0',
            left: '-75%',
            width: '150%',
            height: '100%',
            background: 'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent)',
            transform: 'rotate(25deg)',
            transition: 'all 0.3s ease-in-out',
            zIndex: '-1',
          }}
        ></span>
      </button>
    </div>
  );
}

export default ViewButton;

import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, FormControl, InputGroup } from 'react-bootstrap';
import { FaSearch, FaStar } from 'react-icons/fa';
import { tourGuides } from '../../dummy/tourGuides';
import ViewButton from '../component/ViewButton';
import Footer from '../../component/Footer';
import LoadGuides from '../component/LoadGuides';
import AdvertisementCard from '../component/AdvertisementCard';


function AllGuides() {

    

    const [searchTerm, setSearchTerm] = useState('');
    const ad="Unlock the Extraordinary! ✨ Discover deals you can't resist! From everyday essentials to exclusive luxuries, we've got you covered.Shop now and save big!";

    // Filter guides based on search input
    const filteredGuides = tourGuides.filter(guide =>
        guide.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        guide.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
        guide.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            <Container className="my-5">
                {/* Hero Section */}
                <Row className="text-center">
                    <Col>
                        <h2 className="display-4 text-success mb-3">Discover Experienced Local Guides</h2>
                        <p className="lead text-muted mb-4">Find the best tour guides around the world to show you the hidden gems and cultural treasures of each location.</p>
                    </Col>
                </Row>

                {/* Search Bar */}
                <Row className="justify-content-center mb-5">
                    <Col md={8} lg={6}>
                        <InputGroup size="lg">
                            <InputGroup.Text><FaSearch /></InputGroup.Text>
                            <FormControl
                                type="text"
                                placeholder="Search by name, location or specialty"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </InputGroup>
                    </Col>
                </Row>

                <LoadGuides allGuides={tourGuides} />
                {/* <AdvertisementCard ad={ad}/> */}

            </Container>
            <Footer />
        </>
    );
}

export default AllGuides;

import axios from 'axios';

//actions types

export const FETCH_ITEMS_REQUEST = 'FETCH_ITEMS_REQUEST';
export const FETCH_ITEMS_SUCCESS = 'FETCH_ITEMS_SUCCESS';
export const FETCH_ITEMS_FAILURE = 'FETCH_ITEMS_FAILURE';

export const FETCH_ITEM_DETAILS_REQUEST = 'FETCH_ITEM_DETAILS_REQUEST';
export const FETCH_ITEM_DETAILS_SUCCESS = 'FETCH_ITEM_DETAILS_SUCCESS';
export const FETCH_ITEM_DETAILS_FAILURE = 'FETCH_ITEM_DETAILS_FAILURE';

export const FETCH_PRODUCT_FORECAST_BY_PRODUCT_ID_REQUEST = 'FETCH_PRODUCT_FORECAST_BY_PRODUCT_ID_REQUEST';
export const FETCH_PRODUCT_FORECAST_BY_PRODUCT_ID_SUCCESS = 'FETCH_PRODUCT_FORECAST_BY_PRODUCT_ID_SUCCESS';
export const FETCH_PRODUCT_FORECAST_BY_PRODUCT_ID_FAILURE = 'FETCH_PRODUCT_FORECAST_BY_PRODUCT_ID_FAILURE';

export const FETCH_PRODUCT_FORECAST_DETAILS_REQUEST = 'FETCH_PRODUCT_FORECAST_DETAILS_REQUEST';
export const FETCH_PRODUCT_FORECAST_DETAILS_SUCCESS = 'FETCH_PRODUCT_FORECAST_DETAILS_SUCCESS';
export const FETCH_PRODUCT_FORECAST_DETAILS_FAILURE = 'FETCH_PRODUCT_FORECAST_DETAILS_FAILURE';



//action creaters

export const fetchItemsRequest = () => {
    return {
        type: FETCH_ITEMS_REQUEST,
    }
}

export const fetchItemsSuccess = (items) => {
    return {
        type: FETCH_ITEMS_SUCCESS,
        payload: items,
    }
}

export const fetchItemsFailure = (error) => {
    return {
        type: FETCH_ITEMS_FAILURE,
        payload: error,
    }
}

// fetch product details by product id

export const fetchItemDetailsRequest = () => {
    return {
        type: FETCH_ITEM_DETAILS_REQUEST,
    }
}

export const fetchItemDetailsSuccess = (item) => {
    return {
        type: FETCH_ITEM_DETAILS_SUCCESS,
        payload: item,
    }
}

export const fetchItemDetailsFailure = (error) => {
    return {
        type: FETCH_ITEM_DETAILS_FAILURE,
        payload: error,
    }
}

//fetch product forecast details by product id
export const fetchProductForecastDetailsRequest = () => {
    return {
        type: FETCH_PRODUCT_FORECAST_BY_PRODUCT_ID_REQUEST,
    }
}

export const fetchProductForecastDetailsSuccess = (itemDetails) => {
    return {
        type: FETCH_PRODUCT_FORECAST_BY_PRODUCT_ID_SUCCESS,
        payload: itemDetails,
    }
}

export const fetchProductForecastDetailsFailure = (error) => {
    return {
        type: FETCH_PRODUCT_FORECAST_BY_PRODUCT_ID_FAILURE,
        payload: error,
    }
}

//fetch all product forecast details
export const fetchAllProductForecastDetailsRequest = () => {
    return {
        type: FETCH_PRODUCT_FORECAST_DETAILS_REQUEST,
    }
}

export const fetchAllProductForecastDetailsSuccess = (forcastDetailsAll) => {
    return {
        type: FETCH_PRODUCT_FORECAST_DETAILS_SUCCESS,
        payload: forcastDetailsAll,
    }
}

export const fetchAllProductForecastDetailsFailure = (error) => {
    return {
        type: FETCH_PRODUCT_FORECAST_DETAILS_FAILURE,
        payload: error,
    }
}

//asyncronous actions to fetch items


export const fetchItems = () => {
    return async (dispatch) => {
        dispatch(fetchItemsRequest());
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/product-list/`);
            dispatch(fetchItemsSuccess(response.data))
        } catch (error) {
            dispatch(fetchItemsFailure(error.message))
        }
    }
}

//get all product forcast details

export const fetchAllProductForecastDetailsByProduct = () => {
    return async (dispatch) => {
        dispatch(fetchAllProductForecastDetailsRequest());
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/forecasts/`);
            console.log("product forecast data : ", response.data);
            dispatch(fetchAllProductForecastDetailsSuccess(response.data))
        } catch (error) {
            dispatch(fetchAllProductForecastDetailsFailure(error.message))
        }
    }
}

//get product using product id

export const fetchProductDetailsByProductId = (productId) => {
    console.log(productId);
    return async (dispatch) => {
        dispatch(fetchItemDetailsRequest(productId));
        console.log(productId);
        try {
            console.log(productId);
            console.log(`${process.env.REACT_APP_BACKEND_URL}`);

            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/product-detail/${productId}/`);
            dispatch(fetchItemDetailsSuccess(response.data))
            console.log("redux responce ", response.data);

        } catch (error) {
            dispatch(fetchItemDetailsFailure(error.message))
        }
    }
}

//get product forecast details

export const fetchProductForecastDetailsByProductId = (productId) => {
    console.log(productId);
    return async (dispatch) => {
        dispatch(fetchProductForecastDetailsRequest(productId));
        console.log(productId);
        try {
            console.log(productId);
            console.log(`${process.env.REACT_APP_BACKEND_URL}`);
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/product-forecast-stages/product/${productId}/`);

            dispatch(fetchProductForecastDetailsSuccess(response.data))
            console.log("redux responce product forecast", response.data);

        } catch (error) {
            dispatch(fetchProductForecastDetailsFailure(error.message))
        }
    }
}
import React from 'react';
import { Container, Row, Col, Card, ListGroup, Image, Badge, Button } from 'react-bootstrap';
import { FaEnvelope, FaPhone, FaMapMarkerAlt, FaStar, FaCamera } from 'react-icons/fa';
import './styles/UserProfile.css';
import { tourGuides } from '../../dummy/tourGuides';
import { useParams } from 'react-router-dom';

function UserProfile() {
  const { id } = useParams();
  const guideIdNumber = parseInt(id, 10);
  const currentUser = tourGuides.find((guide) => guide.id === guideIdNumber);

  if (!currentUser) {
    return <div>User not found.</div>;
  }

  return (
    <div className="user-profile">
      {/* Hero Section */}
      <div
        style={{
          backgroundImage: `url(${currentUser.banner || 'https://cdn.tourradar.com/s3/content-pages/952/1024x460/gJjTcT.jpg'})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '300px',
          position: 'relative',
        }}
      >
        <div
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        />
        <div className="d-flex justify-content-center align-items-center h-100">
          <div className="text-center text-white">
            <h1 className="fw-bold">{currentUser.name}</h1>
            <p>{currentUser.location}</p>
          </div>
        </div>
      </div>

      {/* Profile Details */}
      <Container className="my-5">
        <Row>
          <Col md={4}>
            <Card className="shadow-lg">
              <Card.Body className="text-center">
                <Image
                  src={currentUser.image}
                  roundedCircle
                  className="mb-3"
                  alt="Profile"
                  style={{
                    width: '120px',
                    height: '120px',
                    objectFit: 'cover',
                    border: '3px solid #28a745',
                  }}
                />
                <Card.Title>{currentUser.name}</Card.Title>
                <p className="text-muted">{currentUser.bio || 'Passionate tour guide'}</p>
                <Badge bg="success" className="mb-3">
                  Verified Guide
                </Badge>
                <ListGroup variant="flush" className="text-start">
                  <ListGroup.Item>
                    <FaEnvelope className="me-2 text-success" />
                    {currentUser.mail}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <FaPhone className="me-2 text-success" />
                    {currentUser.phone}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <FaMapMarkerAlt className="me-2 text-success" />
                    {currentUser.location}
                  </ListGroup.Item>
                </ListGroup>
                <Button variant="success" className="mt-3">
                  Contact {currentUser.name}
                </Button>
              </Card.Body>
            </Card>
          </Col>

          {/* Gallery Section */}
          <Col md={8}>
            <Card className="shadow-lg">
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <h4>Gallery</h4>
                  <Button variant="outline-success" size="sm">
                    <FaCamera className="me-1" />
                    Add Photo
                  </Button>
                </div>
                <Row>
                  {currentUser.gallery?.length > 0
                    ? currentUser.gallery.map((image, idx) => (
                        <Col xs={6} md={4} className="mb-3" key={idx}>
                          <Card className="border-0">
                            <Card.Img
                              variant="top"
                              src={image}
                              alt={`Gallery Image ${idx + 1}`}
                              className="rounded"
                              style={{ height: '150px', objectFit: 'cover' }}
                            />
                          </Card>
                        </Col>
                      ))
                    : Array.from({ length: 6 }).map((_, idx) => (
                        <Col xs={6} md={4} className="mb-3" key={idx}>
                          <Card className="border-0">
                            <Card.Img
                              variant="top"
                              src={`https://via.placeholder.com/200?text=Image+${idx + 1}`}
                              alt={`Placeholder Image ${idx + 1}`}
                              className="rounded"
                              style={{ height: '150px', objectFit: 'cover' }}
                            />
                          </Card>
                        </Col>
                      ))}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default UserProfile;

import React from 'react'
import './item.css'
import { useNavigate } from 'react-router-dom';
import { FaTag, FaStore, FaCogs, FaInfoCircle } from 'react-icons/fa';

function Item({ item }) {

  const navigate = useNavigate();

  const navigateToBom = (item) => {
    console.log(item);
    navigate(`/bom/${item.product_id}`);
  }

  console.log(item.image1_url);

  return (
    <div className='container mt-5'>
      <div className='item-searched-card' onClick={() => { navigateToBom(item) }} >
        <div className='row w-100 d-flex justify-content-center align-items-center'>
          <div className='items-data-container'>
            <div className="image-container">
              <div className='image-cover'>
                <img
                  src={item.image1_url}
                  className="item-image"
                  alt="Cover"
                />
              </div>
            </div>

            <div>
            <div className='item-data' style={{ lineHeight: '1.2' }}>
              {/* <div className='item-data'> */}
                {/* <h3>{item.name}</h3> */}
                <h5><strong>{item.name}</strong></h5><br></br>
                {/* <p class="custom-heading"><strong>{item.name}</strong></p> */}


                
                <div className='item-details'>
                  <div className='item-detail'>
                    <p>{item.product_version} Version</p>
                  </div>
                  <div className='item-detail'>
                    <p>{item.brand}</p>
                  </div>
                  <div className='item-detail1'>
                    <p>{item.seller}</p>
                  </div>
                  <div className='item-detail'>
                    {/* <FaInfoCircle className='icon' /> */}
                    {/* Optionally add description or another detail */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Item;

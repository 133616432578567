import React, { useEffect, useState } from 'react'
import { Card, Button } from 'react-bootstrap';
import AllGuides from '../pages/AllGuides';
import { FaStar } from 'react-icons/fa';
import ViewButton from './ViewButton';

function LoadGuides({ allGuides }) {

    const [guideLists, setGuideList] = useState([]);
    const [visibleCount, setVisibleCount] = useState(8);
    const itemsPerPage = 8;

    useEffect(() => {
        setGuideList(allGuides.slice(0, visibleCount));
    }, [visibleCount, allGuides])

    const loadMore = () => {
        const newCount = visibleCount + itemsPerPage;
        setVisibleCount(newCount)
    }

    const handleGuidesRatings = (guid) => {
        const rating = guid.rating;
        const fullStars = Array(Math.floor(rating)).fill(true);
        console.log("fullStars", fullStars);
        const emptyStars = Array(5 - Math.floor(rating)).fill(false);
        console.log("emptyStars", emptyStars);
        return (
            <>
                <div>
                    {fullStars.map((_, index) => (
                        <FaStar key={index} className='star filled' />
                    ))}
                    {emptyStars.map((_, index) => (
                        <FaStar key={index} className='star empty' />
                    ))}
                </div>
            </>
        )
    }

    return (
        <div>
            <div>
                <div className="row">
                    {guideLists.map((guide) => (
                        <div key={guide.id} className="col-md-3">
                            <div className='my-2'>
                                <Card
                                    key={guide.id}
                                    className="p-3 shadow-sm h-100  position-relative text-white"
                                    style={{
                                        backgroundImage: `url(${guide.image})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat',
                                    }}
                                >
                                    {/* Dark overlay */}
                                    <div
                                        className="position-absolute top-0 start-0 w-100 h-100"
                                        style={{
                                            backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent black
                                            zIndex: 1, // Ensure the overlay appears on top of the background image
                                        }}
                                    ></div>

                                    {/* Content */}
                                    <div className="position-relative" style={{ zIndex: 2 }}>
                                        <div className="d-flex align-items-center mb-3">
                                            <div className="me-3">
                                                <img
                                                    src={guide.image}
                                                    alt={`${guide.name}'s profile`}
                                                    className="rounded-circle"
                                                    style={{
                                                        width: '80px',
                                                        height: '80px',
                                                        objectFit: 'cover',
                                                        border: '2px solid #28a745',
                                                    }}
                                                />
                                            </div>
                                            <div>
                                                <h5 className="mb-1 text-white">{guide.name}</h5>
                                                <p className="text-muted mb-1">{guide.location}</p>
                                            </div>
                                        </div>
                                        <Card.Body className="px-0">
                                            <Card.Text>{guide.description}</Card.Text>
                                            <Card.Text>{handleGuidesRatings(guide)}</Card.Text>
                                        </Card.Body>
                                        <Card.Footer className="bg-transparent px-0">
                                            <ViewButton name={'More About Me'} />
                                        </Card.Footer>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    ))}
                </div>
                {visibleCount < allGuides.length && (
                    <div className="text-center mt-4">
                        <Button onClick={loadMore} variant="success" className="rounded-pill px-4">
                            Load More
                        </Button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default LoadGuides
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

function JoinMeeting() {
    const { token } = useParams(); // If token is part of the URL path

    useEffect(() => {
        if (token) {
            // Construct the Android app link dynamically
            const androidAppLink = `https://greenbless.com/joinMeeting?token=${token}`;
            window.location.href = androidAppLink; // Redirect to the Android app
        }
    }, [token]);

    return (
        <div className='d-flex justify-content-center align-items-center'>
            Redirecting to the meeting...
        </div>
    );
}


export default JoinMeeting;
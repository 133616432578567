import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import { FaUpload, FaDollarSign, FaMapMarkerAlt, FaCalendarAlt, FaTimes } from 'react-icons/fa';
import './styles/PackageCreate.css';

function PackageCreate() {
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        price: '',
        startDate: '',
        endDate: '',
        images: [],
        country: '',  // Selected main country
        semiLocations: [],  // Selected semi-locations within the country
    });

    // Data for main countries and their corresponding semi-locations
    const countryData = {
        "USA": ["New York", "Los Angeles", "Chicago", "San Francisco"],
        "France": ["Paris", "Nice", "Lyon", "Marseille"],
        "Japan": ["Tokyo", "Kyoto", "Osaka", "Hokkaido"],
        "Italy": ["Rome", "Venice", "Florence", "Milan"],
        "UAE": ["Dubai", "Abu Dhabi", "Sharjah"],
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFileUpload = (e) => {
        const files = Array.from(e.target.files);
        setFormData((prevData) => ({
            ...prevData,
            images: [...prevData.images, ...files],
        }));
    };

    const handleCountryChange = (e) => {
        const selectedCountry = e.target.value;
        setFormData((prevData) => ({
            ...prevData,
            country: selectedCountry,
            semiLocations: [], // Reset semi-locations when country changes
        }));
    };

    const handleSemiLocationChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
        setFormData((prevData) => ({
            ...prevData,
            semiLocations: selectedOptions,
        }));
    };

    const handleRemoveSemiLocation = (semiLocation) => {
        setFormData((prevData) => ({
            ...prevData,
            semiLocations: prevData.semiLocations.filter(loc => loc !== semiLocation),
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        alert('Package created successfully!');
    };

    return (
        <>
            <Container className="my-5">
                <Row>
                    <Col md={8} className="mx-auto">
                        <Card className="shadow p-4">
                            <h2 className="mb-4 text-center">Create Travel Package</h2>
                            <Form onSubmit={handleSubmit}>
                                {/* Package Title */}
                                <Form.Group className="mb-3" controlId="title">
                                    <Form.Label>Package Title</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="title"
                                        placeholder="Enter package title"
                                        value={formData.title}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>

                                {/* Country Selection */}
                                <Form.Group className="mb-3" controlId="country">
                                    <Form.Label>
                                        <FaMapMarkerAlt className="me-2" />
                                        Select Country
                                    </Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="country"
                                        value={formData.country}
                                        onChange={handleCountryChange}
                                        required
                                    >
                                        <option value="">Select a country</option>
                                        {Object.keys(countryData).map((country, idx) => (
                                            <option key={idx} value={country}>{country}</option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>

                                {/* Semi-Locations (Cities or Landmarks) */}
                                {formData.country && (
                                    <Form.Group className="mb-3" controlId="semiLocations">
                                        <Form.Label>
                                            <FaMapMarkerAlt className="me-2" />
                                            Select Semi-Locations
                                        </Form.Label>
                                        <Form.Control
                                            as="select"
                                            name="semiLocations"
                                            value={formData.semiLocations}
                                            onChange={handleSemiLocationChange}
                                            multiple
                                            required
                                        >
                                            {countryData[formData.country].map((location, idx) => (
                                                <option key={idx} value={location}>{location}</option>
                                            ))}
                                        </Form.Control>
                                        <Form.Text className="text-muted">
                                            Hold down 'Ctrl' or 'Cmd' to select multiple semi-locations.
                                        </Form.Text>
                                    </Form.Group>
                                )}

                                {/* Display Selected Semi-Locations */}
                                <div className="mb-3">
                                    <strong>Selected Semi-Locations: </strong>
                                    {formData.semiLocations.length > 0 ? (
                                        <ul>
                                            {formData.semiLocations.map((semiLocation, idx) => (
                                                <li key={idx} className="d-flex justify-content-between">
                                                    {semiLocation}
                                                    <FaTimes
                                                        className="text-danger ms-2"
                                                        onClick={() => handleRemoveSemiLocation(semiLocation)}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p>No semi-locations selected.</p>
                                    )}
                                </div>

                                {/* Package Description */}
                                <Form.Group className="mb-3" controlId="description">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="description"
                                        rows={4}
                                        placeholder="Describe the package details"
                                        value={formData.description}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>

                                {/* Price */}
                                <Form.Group className="mb-3" controlId="price">
                                    <Form.Label>
                                        <FaDollarSign className="me-2" />
                                        Price
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="price"
                                        placeholder="Enter price in USD"
                                        value={formData.price}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>

                                {/* Dates */}
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="startDate">
                                            <Form.Label>
                                                <FaCalendarAlt className="me-2" />
                                                Start Date
                                            </Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="startDate"
                                                value={formData.startDate}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className="mb-3" controlId="endDate">
                                            <Form.Label>End Date</Form.Label>
                                            <Form.Control
                                                type="date"
                                                name="endDate"
                                                value={formData.endDate}
                                                onChange={handleInputChange}
                                                required
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                {/* Image Upload */}
                                <Form.Group className="mb-4" controlId="images">
                                    <Form.Label>
                                        <FaUpload className="me-2" />
                                        Upload Images
                                    </Form.Label>
                                    <Form.Control
                                        type="file"
                                        multiple
                                        accept="image/*"
                                        onChange={handleFileUpload}
                                    />
                                    <Form.Text className="text-muted">
                                        You can upload multiple images.
                                    </Form.Text>
                                </Form.Group>

                                {/* Preview Images */}
                                <Row className="mb-3">
                                    {formData.images.map((file, idx) => (
                                        <Col xs={6} md={4} key={idx} className="mb-3">
                                            <Card>
                                                <Card.Img
                                                    variant="top"
                                                    src={URL.createObjectURL(file)}
                                                    alt={`Preview ${idx + 1}`}
                                                />
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>

                                {/* Submit Button */}
                                <Button variant="primary" type="submit" className="w-100">
                                    Create Package
                                </Button>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default PackageCreate;

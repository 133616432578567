import React, { useState } from "react";
import { Container, Row, Col, Button, Dropdown, Form, Modal, Table, Card } from "react-bootstrap";
import { FaPlus, FaEdit, FaTrashAlt, FaCogs } from "react-icons/fa";
import './styles/managementResourceGroup.css';

function ManagementResourceGroup() {
    const [resources, setResources] = useState([]);
    const [operationList, setOperationList] = useState([]);
    const [createdGroups, setCreatedGroups] = useState([]); // State to track created groups
    const [showResourceModal, setShowResourceModal] = useState(false);
    const [showOperationModal, setShowOperationModal] = useState(false);
    const [editingResource, setEditingResource] = useState(null);
    const [editingOperation, setEditingOperation] = useState(null);
    const [resourceName, setResourceName] = useState("");
    const [resourceDescription, setResourceDescription] = useState("");
    const [operationName, setOperationName] = useState("");
    const [operationDescription, setOperationDescription] = useState("");
    const [capacityType, setCapacityType] = useState('');
    const [availability, setAvailability] = useState('');

    const groupTypes = ["Type 1", "Type 2", "Type 3"];


    const handleDropdownChange = (name, value) => {
        setFormdata((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };


    const [formdata, setFormdata] = useState({
        group_name: '',
        capacity_type: '',
        availability: false,
        group_type: '',

    })

    const [formResourcedata, setFormResourcedata] = useState({
        resource_name: '',
        resource_description: '',
        resource_type: false,
        availability: '',
        cost_rate: '',

    })


    // Add Group
    const handleAddGroup = (e) => {
        e.preventDefault(); // Prevent the default form submission behavior
        console.log(formdata.group_name);
        console.log(formdata.capacity_type);
        console.log(formdata.group_type);
        // Check if required fields are provided
        if (formdata.group_name && formdata.capacity_type && formdata.group_type !== "") {
            // Create the new group object
            const newGroup = {
                name: formdata.group_name,
                operations: formdata.operations,  // Assuming you have operations in formdata
                capacity_type: formdata.capacity_type,
                availability: formdata.availability,
                group_type: formdata.group_type,
            };

            // Add the new group to the createdGroups array (or wherever you store groups)
            setCreatedGroups([...createdGroups, newGroup]);

            // Reset formdata state to clear the form
            setFormdata({
                group_name: "",
                capacity_type: "",
                availability: false,
                group_type: "",
                operations: "",  // Assuming you have operations in formdata as well
            });
        } else {
            // Handle validation if fields are missing
            alert("Please fill in all required fields.");
        }
    };



    // Add Resource
    const handleAddResource = () => {
        // Check if all required fields are filled
        const requiredFields = ['resource_name', 'resource_description'];
        const isFormValid = requiredFields.every((field) => formResourcedata[field].trim() !== "");
    
        if (!isFormValid) {
            alert("Please fill in all required fields."); // You can replace this with better feedback like a toast notification
            return;
        }
    
        // Add the resource to the list
        setResources((prevResources) => [
            ...prevResources,
            {
                name: formResourcedata.resource_name,
                description: formResourcedata.resource_description,
                type: formResourcedata.resource_type,
                availability: formResourcedata.availability,
                costRate: formResourcedata.cost_rate,
            },
        ]);
    
        // Reset the form
        setFormResourcedata({
            resource_name: '',
            resource_description: '',
            resource_type: false,
            availability: '',
            cost_rate: '',
        });
    
        // Close the modal or any associated UI element
        setShowResourceModal(false);
    };
    

    // Edit Resource
    const handleEditResource = (index) => {
        const resource = resources[index];
        setResourceName(resource.name);
        setResourceDescription(resource.description);
        setEditingResource(index);
        setShowResourceModal(true);
    };

    // Save Edited Resource
    const handleSaveEditedResource = () => {
        if (!formResourcedata.resource_name || !formResourcedata.resource_description) {
            alert("Resource name and description are required.");
            return;
        }

        const updatedResources = [...resources];
        updatedResources[editingResource] = {
            ...updatedResources[editingResource], // Preserve existing fields
            name: formResourcedata.resource_name,
            description: formResourcedata.resource_description,
            type: formResourcedata.resource_type,
            availability: formResourcedata.availability,
            costRate: formResourcedata.cost_rate,
        };

        setResources(updatedResources);
        setFormResourcedata({
            resource_name: '',
            resource_description: '',
            resource_type: false,
            availability: '',
            cost_rate: '',
        });
        setEditingResource(null);
        setShowResourceModal(false);
    };

    // Delete Resource
    const handleDeleteResource = (index) => {
        const updatedResources = resources.filter((_, i) => i !== index);
        setResources(updatedResources);
    };

    // Add Operation
    const handleAddOperation = () => {
        if (operationName && operationDescription) {
            setOperationList([
                ...operationList,
                { name: operationName, description: operationDescription },
            ]);
            setOperationName("");
            setOperationDescription("");
            setShowOperationModal(false);
        }
    };

    // Edit Operation
    const handleEditOperation = (index) => {
        const operation = operationList[index];
        setOperationName(operation.name);
        setOperationDescription(operation.description);
        setEditingOperation(index);
        setShowOperationModal(true);
    };

    // Save Edited Operation
    const handleSaveEditedOperation = () => {
        const updatedOperations = [...operationList];
        updatedOperations[editingOperation] = {
            name: operationName,
            description: operationDescription,
        };
        setOperationList(updatedOperations);
        setOperationName("");
        setOperationDescription("");
        setEditingOperation(null);
        setShowOperationModal(false);
    };

    // Delete Operation
    const handleDeleteOperation = (index) => {
        const updatedOperations = operationList.filter((_, i) => i !== index);
        setOperationList(updatedOperations);
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        // For checkboxes, handle the checked state
        if (type === 'checkbox') {
            setFormdata(prevState => ({
                ...prevState,
                [name]: checked
            }));
        } else {
            // For other form fields, handle the value
            setFormdata(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleResourceChange = (e) => {
        const { name, value } = e.target;
        setFormResourcedata((prevData) => ({
            ...prevData,
            [name]: value, // Update the property dynamically based on the name attribute
        }));
    };
    


    return (
        <Container fluid>
            <div>
                <Row>
                    <Col md={5}>
                        <div className="p-4">
                            <h2>Resource Groups</h2>
                            {/* {createdGroups.map((group, index) => (
                                <>
                                    <div className="resource-group-view">
                                        {group.name} - {group.groupType}
                                    </div>
                                </>
                            ))} */}
                            <Table striped bordered hover responsive>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Capacity Type</th>
                                        <th>Availability</th>
                                        <th>Total Capacity</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {createdGroups.map((resource, index) => (
                                        <tr key={index}>
                                            <td>{resource.Id}</td>
                                            <td>{resource.name}</td>
                                            <td>{resource.capacity_type}</td>
                                            <td>{resource.availability}</td>
                                            <td>{resource.total_capacity}</td>
                                            <td>
                                                <Button
                                                    variant="warning"
                                                    onClick={() => handleEditResource(index)}
                                                >
                                                    <FaEdit />
                                                </Button>
                                                <Button
                                                    variant="danger"
                                                    className="ms-2"
                                                    onClick={() => handleDeleteResource(index)}
                                                >
                                                    <FaTrashAlt />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                    <Col md={7}>
                        <div className="p-4">
                            {/* Header Section */}
                            <Row>
                                <Col md={12}>
                                    <h2>Resource Group Management</h2>
                                    <p className="lead">
                                        Manage resource groups, including adding and editing resources and operations.
                                    </p>
                                </Col>
                            </Row>

                            {/* Group Form Section */}
                            <Row className="my-4">
                                <Col md={6}>
                                    <Row>
                                        <Form onSubmit={handleAddGroup}>
                                            <Row className="my-4">
                                                {/* Group Name and Operations (Row 1) */}
                                                <Col md={6}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Group Name</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter group name"
                                                            name="group_name"  // Added name attribute
                                                            value={formdata.group_name}
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                {/* Capacity Type and Availability (Row 2) */}
                                                <Col md={6}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Capacity Type</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter capacity type"
                                                            name="capacity_type"  // Added name attribute
                                                            value={formdata.capacity_type}
                                                            onChange={handleChange}
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Availability</Form.Label>
                                                        <div className="d-flex align-items-center">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="availabilityCheckbox"
                                                                name="availability"
                                                                checked={formdata.availability}
                                                                onChange={handleChange}  // Handle checkbox state change
                                                                aria-label="Availability checkbox"
                                                            />
                                                            <label htmlFor="availabilityCheckbox" className="ms-2">
                                                                {formdata.availability ? 'Available' : 'Not Available'}
                                                            </label>
                                                        </div>
                                                    </Form.Group>
                                                </Col>

                                                {/* Group Type (Row 3) */}
                                                <Col md={6}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Group Type</Form.Label>
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="light" id="dropdown-group-type">
                                                                {formdata.group_type || "Select Group Type"}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                {groupTypes.map((type) => (
                                                                    <Dropdown.Item key={type} onClick={() => handleDropdownChange("group_type", type)}>
                                                                        {type}
                                                                    </Dropdown.Item>
                                                                ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>

                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Button type="submit" variant="primary" className="w-100">
                                                Save Group
                                            </Button>
                                        </Form>

                                    </Row>
                                </Col>
                            </Row>

                            {/* Resources Section */}
                            <Row className="my-4">
                                <Col md={12}>
                                    <Card>
                                        <Card.Body>
                                            <h5>Resources</h5>
                                            <Button
                                                variant="primary"
                                                className="mb-3"
                                                onClick={() => setShowResourceModal(true)}
                                            >
                                                <FaPlus /> Add Resource
                                            </Button>

                                            {/* Resources Table */}
                                            <Table striped bordered hover responsive>
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Resource ID</th>
                                                        <th>Resource Name</th>
                                                        <th>Resource Group ID</th>
                                                        <th>Type</th>
                                                        <th>Availability</th>
                                                        <th>Cost Rate</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {resources.map((resource, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{resource.name}</td>
                                                            <td>{resource.name}</td>
                                                            <td>{resource.name}</td>
                                                            <td>{resource.type}</td>
                                                            <td>{resource.availability}</td>
                                                            <td>{resource.costRate}</td>

                                                            <td>
                                                                <Button
                                                                    variant="warning"
                                                                    onClick={() => handleEditResource(index)}
                                                                >
                                                                    <FaEdit />
                                                                </Button>
                                                                <Button
                                                                    variant="danger"
                                                                    className="ms-2"
                                                                    onClick={() => handleDeleteResource(index)}
                                                                >
                                                                    <FaTrashAlt />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            {/* Operations Section */}
                            <Row className="my-4">
                                <Col md={12}>
                                    <Card>
                                        <Card.Body>
                                            <h5>Operations</h5>
                                            <Button
                                                variant="primary"
                                                className="mb-3"
                                                onClick={() => setShowOperationModal(true)}
                                            >
                                                <FaPlus /> Add Operation
                                            </Button>

                                            {/* Operations Table */}
                                            <Table striped bordered hover responsive>
                                                <thead>
                                                    <tr>
                                                        <th>Operation ID</th>
                                                        <th>Operation Name</th>
                                                        <th>Resource Group ID</th>
                                                        <th>Operation Type</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {operationList.map((operation, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{operation.name}</td>
                                                            <td>{operation.name}</td>
                                                            <td>{operation.description}</td>
                                                            <td>
                                                                <Button
                                                                    variant="warning"
                                                                    onClick={() => handleEditOperation(index)}
                                                                >
                                                                    <FaEdit />
                                                                </Button>
                                                                <Button
                                                                    variant="danger"
                                                                    className="ms-2"
                                                                    onClick={() => handleDeleteOperation(index)}
                                                                >
                                                                    <FaTrashAlt />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                            {/* Resource Modal */}
                            <Modal
                                show={showResourceModal}
                                onHide={() => setShowResourceModal(false)}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>{editingResource !== null ? "Edit Resource" : "Add Resource"}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Resource Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="resource_name" // Add name attribute
                                                value={formResourcedata.resource_name}
                                                onChange={handleResourceChange}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Resource Description</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                name="resource_description" // Add name attribute
                                                value={formResourcedata.resource_description}
                                                onChange={handleResourceChange}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Resource Type</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="resource_type" // Add name attribute
                                                value={formResourcedata.resource_type}
                                                onChange={handleResourceChange}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Availability</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="availability" // Add name attribute
                                                value={formResourcedata.availability}
                                                onChange={handleResourceChange}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Cost Rate</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="cost_rate" // Add name attribute
                                                value={formResourcedata.cost_rate}
                                                onChange={handleResourceChange}
                                            />
                                        </Form.Group>
                                    </Form>

                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="secondary"
                                        onClick={() => setShowResourceModal(false)}
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        variant="primary"
                                        onClick={editingResource !== null ? handleSaveEditedResource : handleAddResource}
                                    >
                                        Save
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            {/* Operation Modal */}
                            <Modal
                                show={showOperationModal}
                                onHide={() => setShowOperationModal(false)}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>{editingOperation !== null ? "Edit Operation" : "Add Operation"}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Operation Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={operationName}
                                                onChange={(e) => setOperationName(e.target.value)}
                                            />
                                        </Form.Group>

                                        <Form.Group className="mb-3">
                                            <Form.Label>Operation Description</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                value={operationDescription}
                                                onChange={(e) => setOperationDescription(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button
                                        variant="secondary"
                                        onClick={() => setShowOperationModal(false)}
                                    >
                                        Close
                                    </Button>
                                    <Button
                                        variant="primary"
                                        onClick={editingOperation !== null ? handleSaveEditedOperation : handleAddOperation}
                                    >
                                        Save
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    );
}

export default ManagementResourceGroup;

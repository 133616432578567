import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FaRegChartBar, FaSeedling, FaWarehouse, FaUsers } from 'react-icons/fa';
import { Line, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import './styles/CenterOperations.css'; // Make sure to import your styles
import GrantChart from '../../component/GrantChart';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);


const ResourceOperations = () => {
    // Example chart data
    const chartData = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
        datasets: [
            {
                label: 'Farm Production',
                data: [65, 59, 80, 81, 56, 55, 40],
                fill: false,
                borderColor: '#28a745',
                tension: 0.1,
            },
        ],
    };

    const pieChartData = {
        labels: ['Wheat', 'Corn', 'Soybeans', 'Vegetables', 'Unused Land'],
        datasets: [
            {
                label: 'Ground Allocation',
                data: [35, 25, 20, 15, 5],
                backgroundColor: [
                    '#f39c12',
                    '#27ae60',
                    '#2980b9',
                    '#8e44ad',
                    '#95a5a6',
                ],
                hoverBackgroundColor: [
                    '#e67e22',
                    '#2ecc71',
                    '#3498db',
                    '#9b59b6',
                    '#7f8c8d',
                ],
            },
        ],
    };

    const tasks = [
        {
            id: 1,
            operation: "Soil Preparation",
            start_date: "2024-01-01",
            end_date: "2024-01-07",
            progress: 50,
        },
        {
            id: 2,
            operation: "Seed Planting",
            start_date: "2024-01-08",
            end_date: "2024-01-14",
            progress: 20,
        },
        {
            id: 3,
            operation: "Irrigation Setup",
            start_date: "2024-01-15",
            end_date: "2024-01-20",
            progress: 40,
        },
        {
            id: 4,
            operation: "Fertilizer Application",
            start_date: "2024-01-21",
            end_date: "2024-01-25",
            progress: 70,
        },
        {
            id: 5,
            operation: "Pest Control",
            start_date: "2024-01-26",
            end_date: "2024-01-30",
            progress: 30,
        },
        {
            id: 6,
            operation: "Harvesting",
            start_date: "2024-02-01",
            end_date: "2024-02-05",
            progress: 10,
        },
    ];


    return (
        <>
            <div className="center-hero-section">
                {/* Hero Section with Background Image */}

                <div className="hero-overlay-center-operation">
                    <div className='contents-hero-section'>
                        <Container fluid className="py-5 text-center text-light">
                            <Row>
                                <Col>
                                    <h1 className="display-4 fw-bold mb-3">Center Operations Dashboard</h1>
                                    <p className="lead mb-4">
                                        Manage your farm's resources, storage, and farmer engagement all in one place.
                                        Optimize your operations for efficient growth and sustainability.
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
            <div>
                
            </div>
            <div className='oparation-contents mt-5'>
                <Container className="py-4">
                    {/* Overview Cards */}
                    <Row className="mb-4">
                        <Col md={3}>
                            <Card className="text-center">
                                <Card.Body>
                                    <FaSeedling style={{ fontSize: '3rem', color: '#28a745' }} />
                                    <h5 className="mt-3">Farm Resources</h5>
                                    <p>Total resources available for your centers.</p>
                                    <Button variant="success">View Resources</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card className="text-center">
                                <Card.Body>
                                    <FaWarehouse style={{ fontSize: '3rem', color: '#28a745' }} />
                                    <h5 className="mt-3">Storage Facilities</h5>
                                    <p>Track storage capacity and availability.</p>
                                    <Button variant="success">Manage Storage</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card className="text-center">
                                <Card.Body>
                                    <FaUsers style={{ fontSize: '3rem', color: '#28a745' }} />
                                    <h5 className="mt-3">Farmer Engagement</h5>
                                    <p>Manage and communicate with your farmers.</p>
                                    <Button variant="success">Manage Farmers</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card className="text-center">
                                <Card.Body>
                                    <FaRegChartBar style={{ fontSize: '3rem', color: '#28a745' }} />
                                    <h5 className="mt-3">Operations Overview</h5>
                                    <p>Get insights into center operations.</p>
                                    <Button variant="success">View Overview</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    {/* Chart Section */}
                    <div className="my-2">
                        <h2 className="text-success">Analyze and Optimize Your Farm Production</h2>
                        <p className="lead">
                            Gain actionable insights into your farm's production trends and performance. Leverage data-driven analytics to make informed decisions for sustainable growth.
                        </p>
                    </div>


                    <Row>
                        <Col md={6}>
                            <div style={{ height: '400px', width: '100%' }}>
                                <Line data={chartData} />
                            </div>
                        </Col>
                        <Col md={6}>
                        <div style={{ height: '400px', width: '100%' }}>
                                <Line data={chartData} />
                            </div>
                        </Col>
                    </Row>

                    <div className="my-2">
                        <h2 className="text-success">Track, Analyze, and Optimize Your Farm Operations</h2>
                        <p className="lead">
                            Visualize the entire farming process with an interactive Gantt chart. Monitor production timelines, identify bottlenecks, and ensure efficient resource allocation for sustainable growth.
                        </p>
                    </div>
                    <Row>
                        <Col md={12}>
                            <GrantChart tasks={tasks} />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default ResourceOperations;

import axios from 'axios';
import { fetchItemDetailsFailure } from './itemActions';

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_FAILURE = 'USER_REGISTER_FAILURE';

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAILURE = 'USER_LOGIN_FAILURE';

export const USER_ROLES_REQUEST = 'USER_ROLES_REQUEST';
export const USER_ROLES_SUCCESS = 'USER_ROLES_SUCCESS';
export const USER_ROLES_FAILURE = 'USER_ROLES_FAILURE';

//user role management

export const userRoleRequest = () => {
    return {
        type: USER_ROLES_REQUEST,
    }
}

export const userRoleSuccess = (roles) => {
    return {
        type: USER_ROLES_SUCCESS,
        payload: roles,
    }
}

export const userRoleFailure = (error) => {
    return {
        type: USER_ROLES_FAILURE,
        payload: error,
    }
}

//user register

export const userRegisterRequest = () => {
    return {
        type: USER_REGISTER_REQUEST,
    }
}

export const userRegisterSuccess = (user) => {
    return {
        type: USER_REGISTER_SUCCESS,
        payload: user,
    }
}

export const userRegisterFailure = (error) => {
    return {
        type: USER_REGISTER_FAILURE,
        payload: error,
    }
}

//user Login

export const userLoginRequest = () => {
    return {
        type: USER_LOGIN_REQUEST,
    }
}


export const userLoginSuccess = (user, token) => {
    localStorage.setItem('AuthToken', token);
    localStorage.setItem('loggedUserName', user.first_name);
    localStorage.setItem('auth', true);
    localStorage.setItem('loggedUserName', user.first_name);
    localStorage.setItem('user_role',user.role);
    return {
        type: USER_LOGIN_SUCCESS,
        payload: {
            user: user,
            token: token,
            login: true
        },
    }
}

export const userLoginFailure = (error) => {

    return {
        type: USER_LOGIN_FAILURE,
        payload: error,
    }
}


//save new user

function getRoleNameByRoleId(roleId) {
    console.log(roleId);
    return async (roleId) => {
        try {
            const responce = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/role/${roleId}`);
            console.log("role name", responce.data);
        } catch (error) {
            console.log("role error", error);

        }
    }
}

export const saveNewUser = (user) => {
    return async (dispatch) => {
        dispatch(userRegisterRequest(user))
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth-register/`, user);
            dispatch(userRegisterSuccess(response.data));
            console.log(response);

        } catch (error) {
            console.log(error);
            dispatch(userRegisterFailure(error.response?.data?.message || error.message))
        }
    }
}

//user login

export const userLoginAndAuthentication = (username, password) => {
    return async (dispatch) => {
        dispatch(userLoginRequest(username, password))
        try {
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth-login/`, { username, password });
            console.log('response', response);
            const { user, access } = response.data;

            dispatch(userLoginSuccess(user, access));
        } catch (error) {
            dispatch(userLoginFailure(error.response?.data?.message || error.message))
        }
    }
}

//fetch user roles

export const fetchAllUserRoles = () => {
    return async (dispatch) => {
        dispatch(userRoleRequest())
        try {
            const responce = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/user-roles/`);
            dispatch(userRoleSuccess(responce.data.roles));

        } catch (error) {
            dispatch(userRoleFailure(error));
        }
    }
}
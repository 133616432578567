import React, { useEffect, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import './styles/register.css';
import axios from 'axios';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllUserRoles, saveNewUser } from '../redux/actions/userAction';

function Register() {
    const state = useSelector((state) => state);
    const { loading, userRoles } = state.user;
    const [registerSuccess, setRegisterSuccess] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setconfirmPassword] = useState('');
    const [error, setError] = useState(false);
    const [serverError, setServerError] = useState(''); // To display errors from the backend
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        username: '',
        email: '',
        address: '',
        phone_number: '',
        password: '',
        confirmPassword: '',
        user_role: ''
    });
    const dispatch = useDispatch();
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    //fetch userroles

    useEffect(() => {
        dispatch(fetchAllUserRoles());
    }, [dispatch])

    // Handle changes to input fields
    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value
        });

        if (name === 'password') {
            setPassword(value);
        }

        if (name === 'confirmPassword') {
            setconfirmPassword(value);
        }
    };

    // Function to check password errors
    const errorCheckForPassword = () => {
        let hasError = false;

        // Password regex for minimum 8 characters with letters and numbers
        const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

        // Check password format
        if (!passwordRegex.test(password)) {
            setPasswordError('Password must be at least 8 characters long and include both letters and numbers.');
            hasError = true;
        } else {
            setPasswordError('');
        }

        // Check if passwords match
        if (password !== confirmPassword) {
            setPasswordError('Passwords do not match');
            hasError = true;
        }

        setError(hasError); // Update error state
        return hasError;    // Return whether there was an error
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setServerError('');

        const hasError = errorCheckForPassword();
        if (hasError) return;

        console.log(formData);
        dispatch(saveNewUser(formData))
            .then(() => setRegisterSuccess(true))
            .catch(err => setServerError(err.message));

        navigate('/login');
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div>
            {!registerSuccess ? (
                <div>
                    <div className='login-screen-container'>
                        <div className='d-flex justify-content-center align-items-center mt-4'>
                            <div className='d-flex justify-content-center align-items-center w-100'>
                                <div className='login-form'>
                                    <form className='login-form' onSubmit={handleSubmit}>
                                        <h2 className='my-4'>Sign Up</h2>
                                        <div className='form-data mt-2'>
                                            <div className='form-group'>
                                                <input
                                                    className='input-Box'
                                                    type='text'
                                                    id='first_name'
                                                    name='first_name'
                                                    value={formData.first_name}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder='First Name'
                                                />
                                            </div>

                                            <div className='form-group'>
                                                <input
                                                    className='input-Box'
                                                    type='text'
                                                    id='last_name'
                                                    name='last_name'
                                                    value={formData.last_name}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder='Last Name'
                                                />
                                            </div>

                                            <div className='form-group'>
                                                <input
                                                    className='input-Box'
                                                    type='text'
                                                    id='username'
                                                    name='username'
                                                    value={formData.username}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder='Username'
                                                />
                                            </div>

                                            <div className='form-group'>
                                                <input
                                                    className='input-Box'
                                                    type='email'
                                                    id='email'
                                                    name='email'
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder='E-mail'
                                                />
                                            </div>

                                            <div className='form-group'>
                                                <input
                                                    className='input-Box'
                                                    type='text'
                                                    id='address'
                                                    name='address'
                                                    value={formData.address}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder='Address'
                                                />
                                            </div>

                                            <div className='form-group'>
                                                <input
                                                    className='input-Box'
                                                    type='text'
                                                    id='phone_number'
                                                    name='phone_number'
                                                    value={formData.phone_number}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder='Phone Number'
                                                />
                                            </div>

                                            <div className='form-group'>
                                                <select
                                                    className='select-one-Box'
                                                    id='role'
                                                    name='user_role'
                                                    value={formData.user_role}
                                                    onChange={handleChange}
                                                    required
                                                >
                                                    <option value='' hidden>Select a Role</option>
                                                    {
                                                        userRoles.map((role) => (
                                                            <option key={role.id} value={role.id}>
                                                                {role.name}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </div>

                                            <div className='form-group password-group'>
                                                <input
                                                    className='input-Box'
                                                    type={showPassword ? 'text' : 'password'}
                                                    id='password'
                                                    name='password'
                                                    value={formData.password}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder='Password'
                                                />
                                                <span className="eye-icon" onClick={togglePasswordVisibility}>
                                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                                </span>
                                            </div>

                                            <div className='form-group password-group'>
                                                <input
                                                    className='input-Box'
                                                    type={showPassword ? 'text' : 'password'}
                                                    id='confirmPassword'
                                                    name='confirmPassword'
                                                    value={formData.confirmPassword}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder='Confirm Password'
                                                />
                                                <span className="eye-icon" onClick={togglePasswordVisibility}>
                                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                                </span>
                                            </div>
                                        </div>

                                        <button type='submit' className='login-button mt-2'>
                                            Sign Up
                                        </button>
                                    </form>
                                    <div className="mt-2 text-center">
                                        <p>
                                            Already have an account?
                                            <Link to="/login" className="text-decoration-none text-primary"> Login here</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Display errors if they exist */}
                    {error || serverError ? (
                        <div className='mt-2 d-flex justify-content-center align-items-center'>
                            <div className='errorBox'>
                                <div className='w-100 d-flex justify-content-center align-items-center'>
                                    <p>{passwordError || serverError}</p>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            ) : (
                // <div className='text-center'>
                //     <div className='d-flex justify-content-center align-item-center mt-5'>
                //         <img src='https://i.gifer.com/7efs.gif' alt='success-animation' />
                //     </div>
                //     <div className='text-center'>
                //         <h5>Registered Successfully</h5>
                //         <Link to="/login"><h6>Sign in</h6></Link>
                //     </div>
                // </div>
                <></>
            )}
        </div>
    );
}

export default Register;

import React from 'react'
import { Table } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

function TableView({ params, columns, para_names }) {


    console.log(para_names);


    return (
        <div>
            {
                params.length > 0 ? (<>
                    <h4 className='my-4'>Bill of Materials</h4>
                    {/* Make the table horizontally scrollable on small screens */}
                    <div className="table-success">
                        <Table bordered >
                            <thead>
                                <tr>
                                    {columns.map((col, index) => (
                                        <th key={index}>{col}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {params.map((item, rowIndex) => (
                                    <tr key={rowIndex} style={{ textAlign: 'justify' }}>
                                        {para_names.map((col, colIndex) => (
                                            <td key={colIndex}>{item[col]}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>

                </>) : (<></>)
            }
        </div>
    )
}

export default TableView






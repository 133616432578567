import React from 'react';
import { Container } from 'react-bootstrap';
import SideBarAgroTech from '../component/SideBarAgroTech';

function AgroTechMainPage() {
  return (
    <>
      {/* Navbar Component */}
      <SideBarAgroTech />

      {/* Main Page Content */}
      <Container className="mt-4">
        <h1>Welcome to AgroTech</h1>
        <p>
          This is the main page of AgroTech, your go-to platform for modern agricultural solutions.
        </p>
      </Container>
    </>
  );
}

export default AgroTechMainPage;

import React, { useState } from 'react';
import { Offcanvas, Nav, NavDropdown, Button } from 'react-bootstrap';
import { FaHome, FaLeaf, FaUser, FaCog, FaBars } from 'react-icons/fa';

function SideBarAgroTech() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      {/* Toggle Button */}
      <Button variant="dark" className="m-3" onClick={handleShow}>
        <FaBars /> Menu
      </Button>

      {/* Sidebar */}
      <Offcanvas show={show} onHide={handleClose} backdrop="static">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <FaLeaf className="me-2" /> AgroTech
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <Nav.Link href="#home">
              <FaHome className="me-2" /> Home
            </Nav.Link>
            <Nav.Link href="#features">
              <FaLeaf className="me-2" /> Features
            </Nav.Link>
            <Nav.Link href="#about">
              <FaUser className="me-2" /> About Us
            </Nav.Link>
            <NavDropdown title="Settings" id="sidebar-nav-dropdown">
              <NavDropdown.Item href="#profile">
                <FaCog className="me-2" /> Profile
              </NavDropdown.Item>
              <NavDropdown.Item href="#account">
                <FaCog className="me-2" /> Account
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#logout">Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default SideBarAgroTech;

import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FaChartLine, FaRegClock, FaBoxOpen, FaUsers } from "react-icons/fa";
import { Link } from "react-router-dom";
import "../Rolling_Forecast/rollingHome.css"; // Ensure your custom styles are in this file
import img from "../assest/forecast.png"; // Ensure the path to your image is correct

export default function RollingHome() {
    return (
        <div className="rolling-home">
            {/* Hero Section */}
            <div
                className="hero-overlay-rolling d-flex align-items-center text-center"
                style={{
                    backgroundImage: `url("https://www.activecyber.com/hs-fs/hubfs/People%20planning%20blog%20(2).png?width=960&height=419&name=People%20planning%20blog%20(2).png")`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: "350px",
                    position: "relative",
                }}
            >
                <div
                    className="dark-overlay"
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.6)",
                        zIndex: 1,
                    }}
                ></div>

                <Container style={{ position: "relative", zIndex: 2 }}>
                    <Row>
                        <Col>
                            <FaChartLine
                                style={{
                                    fontSize: "4rem",
                                    color: "#28a745",
                                }}
                            />
                            <h1 className="display-4 text-light">Welcome to Rolling Forecast</h1>
                            <p className="lead text-light">
                                Optimize your planning with rolling forecasts, offering real-time insights into schedules, inventory, and resources to stay ahead of market demands.
                            </p>
                            <Link to="/rollingLodingData">
                                <Button variant="success" size="lg">
                                    Get Started
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Features Section */}
            <Container className="">
                <Row className="text-center">
                    <Col md={3}>
                        <div className="feature-box mb-4">
                            <FaRegClock style={{ fontSize: "3rem", color: "#28a745" }} />
                            <h4 className="mt-3">Real-Time Scheduling</h4>
                            <p>
                                Keep track of schedules and deadlines efficiently, ensuring timely operations.
                            </p>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className="feature-box mb-4">
                            <FaBoxOpen style={{ fontSize: "3rem", color: "#28a745" }} />
                            <h4 className="mt-3">Inventory Tracking</h4>
                            <p>
                                Manage your inventory seamlessly to avoid overstocking or shortages.
                            </p>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className="feature-box mb-4">
                            <FaUsers style={{ fontSize: "3rem", color: "#28a745" }} />
                            <h4 className="mt-3">Collaboration Tools</h4>
                            <p>
                                Work together with your team to enhance planning and decision-making.
                            </p>
                        </div>
                    </Col>
                    <Col md={3}>
                        <div className="feature-box mb-4">
                            <FaChartLine style={{ fontSize: "3rem", color: "#28a745" }} />
                            <h4 className="mt-3">Data-Driven Insights</h4>
                            <p>
                                Analyze trends and performance for strategic growth and efficiency.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

import React, { useState } from 'react';
import './styles/ManagementResources.css'
import { Card, Col, Form, FormGroup, Row } from 'react-bootstrap';

function ManagementResources() {
    // State to store the resource group and resource details
    const [resourceGroups, setResourceGroups] = useState([]);
    const [resources, setResources] = useState([]);

    // State for input fields
    const [resourceGroupName, setResourceGroupName] = useState('');
    const [resourceName, setResourceName] = useState('');
    const [worker, setWorker] = useState('');
    const [workerName, setWorkerName] = useState('');
    const [vendor, setVendor] = useState('');
    const [selectedResourceGroup, setSelectedResourceGroup] = useState('');

    // Add a new resource group
    const handleAddResourceGroup = () => {
        if (resourceGroupName) {
            setResourceGroups([...resourceGroups, resourceGroupName]);
            setResourceGroupName('');
        }
    };

    // Add a new resource to the selected group
    const handleAddResource = () => {
        if (selectedResourceGroup && resourceName && worker && workerName && vendor) {
            const newResource = {
                resourceName,
                worker,
                workerName,
                vendor,
                group: selectedResourceGroup,
            };
            setResources([...resources, newResource]);
            clearResourceFields();
        }
    };

    // Clear resource input fields
    const clearResourceFields = () => {
        setResourceName('');
        setWorker('');
        setWorkerName('');
        setVendor('');
    };

    return (
        <div className="management-resources">
            <Row>
                <Col md={2}>
        //side bar
                </Col>
                <Col md={10}>
                    <Row>
                        <Card className='p-4'>
                            <Form.Label>Resource</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter operations"

                            />
                            <Form.Label>Type</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter operations"

                            />
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter operations"
                            />
                        </Card>
                        <Card className='p-4'>
                            <Form.Label>Vendor</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter operations"

                            />
                            <Form.Label>Worker</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter operations"

                            />
                            <Form.Label>Worker name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter operations"
                            />
                        </Card>
                        <Card>
                            <h5>Resource Group</h5>

                        </Card>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default ManagementResources;

export const postedPackages = [
    {
        id: 1,
        title: 'Explore Paris',
        destination: 'Paris, France',
        price: 1500,
        image: 'https://www.francetourisme.fr/images/croisiere_seine_paris.webp',
        bids: 21,
        user: {
            name: 'Emily Carter',
            avatar: 'https://randomuser.me/api/portraits/women/12.jpg',
        },
        description: 'Experience the City of Lights with a guided tour of iconic landmarks such as the Eiffel Tower, the Louvre, and a Seine River cruise.',
    },
    {
        id: 2,
        title: 'Safari Adventure',
        destination: 'Kenya, Africa',
        price: 2500,
        image: 'https://www.bluelankatours.com/wp-content/uploads/2023/12/safari-in-Sri-Lanka.jpg',
        bids: 35,
        user: {
            name: 'John Doe',
            avatar: 'https://randomuser.me/api/portraits/men/11.jpg',
        },
        description: 'Embark on a thrilling safari adventure in Kenya. Witness majestic wildlife, including lions, elephants, and rhinos, in their natural habitat.',
    },
    {
        id: 3,
        title: 'Beach Bliss',
        destination: 'Maldives',
        price: 1800,
        image: 'https://www.agoda.com/wp-content/uploads/2024/09/Featured-image-Sensoji-Temple-Asakusa-Tokyo-Japan-1244x700.jpg',
        bids: 25,
        user: {
            name: 'Sophia Lee',
            avatar: 'https://randomuser.me/api/portraits/women/45.jpg',
        },
        description: 'Relax and unwind on the pristine beaches of the Maldives. Enjoy turquoise waters, luxurious resorts, and breathtaking sunsets.',
    },
    {
        id: 4,
        title: 'Discover Tokyo',
        destination: 'Tokyo, Japan',
        price: 2200,
        image: 'https://media.nomadicmatt.com/2024/tokyothings.jpeg',
        bids: 24,
        user: {
            name: 'Takashi Tanaka',
            avatar: 'https://randomuser.me/api/portraits/men/34.jpg',
        },
        description: 'Explore the bustling streets of Tokyo. Visit historic temples, savor delicious sushi, and immerse yourself in the vibrant culture.',
    },
    {
        id: 5,
        title: 'Alaskan Wilderness',
        destination: 'Alaska, USA',
        price: 3000,
        image: 'https://media.istockphoto.com/id/885477838/photo/the-reflection-of-the-aljass-mountain.jpg?s=612x612&w=0&k=20&c=E09d3U4EjCjMSOhyC5j1Q9BpsDsOfxfVvRljHNk24L8=',
        bids: 15,
        user: {
            name: 'Robert King',
            avatar: 'https://randomuser.me/api/portraits/men/22.jpg',
        },
        description: 'Discover the rugged beauty of Alaska. Hike glaciers, see the Northern Lights, and encounter wildlife like bears and whales.',
    },
    {
        id: 6,
        title: 'Santorini Escape',
        destination: 'Santorini, Greece',
        price: 2000,
        image: 'https://images.unsplash.com/photo-1507525428034-b723cf961d3e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&q=80&w=400',
        bids: 11,
        user: {
            name: 'Maria Papadopoulos',
            avatar: 'https://randomuser.me/api/portraits/women/54.jpg',
        },
        description: 'Escape to the picturesque island of Santorini. Enjoy its iconic white-washed buildings, crystal-clear waters, and world-class cuisine.',
    },
];

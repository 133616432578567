import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Container, Row, Col } from 'react-bootstrap';
import img from '../assest/weather-background.jpg';
import '../Rolling_Forecast/lodingData.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllProductForecastDetailsByProduct } from '../redux/actions/itemActions';

const RollingForecastOrderPage = () => {
  const [data, setData] = useState([]);
  const state = useSelector((state) => state);
  const { loading, allProductForecastDetails, error } = state.itemsData;
  const dispatch = useDispatch();



  useEffect(() => {
    dispatch(fetchAllProductForecastDetailsByProduct());
  }, [dispatch]);

  return (
    <div>
      {/* Hero Section */}
      <div
        style={{
          backgroundImage: `url("https://www.cirrusinsight.com/hs-fs/hubfs/Sales%20Forecast%20Methodology.jpg?width=1999&name=Sales%20Forecast%20Methodology.jpg")`,
          backgroundSize: 'cover',
          height: '400px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#fff',
        }}
      >
        <div className="text-center">
          <h1>Rolling Forecast Orders</h1>
          <p>Your forecasted orders data</p>
        </div>
      </div>

      {/* Table Section */}
      <Container>
        <Row className="mt-5">
          <Col >
            <h2 className=" mb-4" style={{ color: 'green', textAlign: 'left' }}>Rolling Forecast Orders</h2>
            <Table striped bordered hover>
              <thead style={{ backgroundColor: 'green', color: '#fff' }}>
                <tr>
                  <th>Product Code</th>
                  <th>Order Type</th>
                  <th>Quantity</th>
                  <th>Sales Price</th>
                  <th>Revenue</th>
                  <th>Customer</th>
                  <th>Order Number</th>
                  <th>Status</th>
                  <th>ETD</th>
                </tr>
              </thead>
              <tbody>
                {allProductForecastDetails.map((order, index) => (


                  <tr key={index}>
                    <td>{order.product}</td>
                    <td>{order.order_type}</td>
                    <td>{order.quantity}</td>
                    <td>{order.sales_price}</td>
                    <td>{order.revenue}</td>
                    <td>{order.customer}</td>
                    <td>{order.order_number}</td>
                    <td>{order.status}</td>
                    <td>{order.date}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default RollingForecastOrderPage;

import React from 'react';
import { Card, Button } from 'react-bootstrap';
import Slider from 'react-slick';
import { FaStar } from 'react-icons/fa';
import { places } from '../../dummy/Places';

function BeautifulPlacesSlider() {
  // Slider settings
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 100,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Set the autoplay speed (in milliseconds)
    pauseOnHover: true, // Pause autoplay on hover
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <Slider {...settings} className="my-4">
      {places ? (
        places.map((place, index) => (
          <div key={index}>
            <Card className="p-3 shadow-sm h-100 m-2" style={{ borderRadius: '15px', overflow: 'hidden' }}>
              <div>
                <img
                  src={place.image}
                  alt={place.name}
                  className="w-100"
                  style={{
                    height: '250px',
                    objectFit: 'cover',
                    borderRadius: '12px',
                  }}
                />
              </div>
              <Card.Body>
                <h5 className="fw-bold">{place.name}</h5>
                <p className="text-muted">{place.location}</p>
                <p>{place.description}</p>
                <div>
                  <FaStar className="star filled" /> <FaStar className="star filled" /> <FaStar className="star filled" /> <FaStar className="star empty" /> <FaStar className="star empty" />
                </div>
              </Card.Body>
              <Card.Footer className="bg-white">
                <Button variant="success" className="w-100 rounded-pill">Learn More</Button>
              </Card.Footer>
            </Card>
          </div>
        ))
      ) : (
        <p>No beautiful places available.</p>
      )}
    </Slider>
  );
}

export default BeautifulPlacesSlider;

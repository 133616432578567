export const places = [
    {
        name: "Bora Bora",
        location: "French Polynesia",
        image: "https://cache.marriott.com/content/dam/marriott-renditions/BOBXR/bobxr-exterior-aerialview-1580-hor-wide.jpg?output-quality=70&interpolation=progressive-bilinear&downsize=2880px:*",
        description: "Bora Bora is a beautiful island in the South Pacific, known for its crystal-clear waters and stunning resorts."
    },
    {
        name: "Grand Canyon",
        location: "Arizona, USA",
        image: "https://www.nps.gov/grca/planyourvisit/images/mather-point-2021.jpg",
        description: "The Grand Canyon is a majestic natural wonder, with breathtaking views and hiking trails."
    },
    {
        name: "Machu Picchu",
        location: "Peru",
        image: "https://i.natgeofe.com/n/5bcb3613-ba9c-451f-a4d7-58201fd27b53/01-machu-picchu-secrets_16x9.jpg",
        description: "Machu Picchu is an ancient Incan city set high in the Andes Mountains, known for its archaeological significance."
    },
];

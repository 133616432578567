import React from 'react'
import './Footer.css'

function Footer() {
  return (
    <div>
      <footer className="footer">
            <div className="footer-content">
                <p>&copy; {new Date().getFullYear()} GreenBless Travel. All rights reserved.</p>
            </div>
        </footer>
    </div>
  )
}

export default Footer

import {
    FETCH_ITEMS_FAILURE,
    FETCH_ITEMS_REQUEST,
    FETCH_ITEMS_SUCCESS,
    FETCH_ITEM_DETAILS_SUCCESS,
    FETCH_ITEM_DETAILS_REQUEST,
    FETCH_ITEM_DETAILS_FAILURE,
    FETCH_PRODUCT_FORECAST_BY_PRODUCT_ID_FAILURE,
    FETCH_PRODUCT_FORECAST_BY_PRODUCT_ID_REQUEST,
    FETCH_PRODUCT_FORECAST_BY_PRODUCT_ID_SUCCESS,
    FETCH_PRODUCT_FORECAST_DETAILS_REQUEST,
    FETCH_PRODUCT_FORECAST_DETAILS_SUCCESS,
    FETCH_PRODUCT_FORECAST_DETAILS_FAILURE
} from "../actions/itemActions";

const initialState = {
    loading: false,
    items: [],
    itemDetails: {},
    productForcastDetails: {},
    allProductForecastDetails: [],
    error: ''
};

const itemReducer = (state = initialState, action) => {
    switch (action.type) {
        // For fetching the list of items
        case FETCH_ITEMS_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case FETCH_ITEMS_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload,
                error: '',
            };

        case FETCH_ITEMS_FAILURE:
            return {
                ...state,
                loading: false,
                items: [],
                error: action.payload,
            };

            // For fetching item details
        case FETCH_ITEM_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case FETCH_ITEM_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                itemDetails: action.payload, // Store item details in state
                error: '',
            };

        case FETCH_ITEM_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                itemDetails: {}, // Reset item details on failure
                error: action.payload,
            };

            // For fetching product forecast details

        case FETCH_PRODUCT_FORECAST_BY_PRODUCT_ID_REQUEST:
            return {
                ...state,
                loading: false,
            };

        case FETCH_PRODUCT_FORECAST_BY_PRODUCT_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                productForcastDetails: action.payload,
                error: '',
            };

        case FETCH_PRODUCT_FORECAST_BY_PRODUCT_ID_FAILURE:
            return {
                ...state,
                loading: false,
                productForcastDetails: {},
                error: action.payload,
            };

            //for fetching product forecast detaisl all

        case FETCH_PRODUCT_FORECAST_DETAILS_REQUEST:
            console.log("FETCH_PRODUCT_FORECAST_DETAILS_REQUEST")
            return {
                ...state,
                loading: false,
            };

        case FETCH_PRODUCT_FORECAST_DETAILS_SUCCESS:
            console.log("FETCH_PRODUCT_FORECAST_DETAILS_SUCCESS")
            return {
                ...state,
                loading: false,
                allProductForecastDetails: action.payload,
                error: '',
            };

        case FETCH_PRODUCT_FORECAST_DETAILS_FAILURE:
            console.log("FETCH_PRODUCT_FORECAST_DETAILS_FAILURE")
            return {
                ...state,
                loading: false,
                allProductForecastDetails: {},
                error: action.payload,
            };

        default:
            return state;
    }
};

export default itemReducer;
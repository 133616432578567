import React, { useState } from 'react';
import { Container, Row, Col, Button, InputGroup, FormControl, Card, Image,Carousel } from 'react-bootstrap';
import { AiOutlineSearch, AiOutlineSafetyCertificate, AiOutlineStar, AiOutlineGlobal, AiOutlineHeart, AiOutlineCheckCircle, AiOutlineSmile } from 'react-icons/ai';
import { FaArrowRight } from 'react-icons/fa';
import TourSlider from './component/TourSlider';
import './styles/HomeTour.css'
import { tourGuides } from '../dummy/tourGuides';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../component/Footer';
import BeautifulPlacesSlider from './component/PlaceSlider';
import { postedPackages } from '../dummy/postedPackages';

const HomeTour = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const navigate = useNavigate();

    // Filtered tour guides based on search query
    const filteredGuides = tourGuides.filter((guide) =>
        guide.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        guide.location.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const navigateToShowAllGuides = () => {
        navigate('/allGuides');
    }

    return (
        <div style={{ height: '100vh' }}>
            {/* Hero Section */}
            <div
                style={{
                    position: "relative",
                    overflow: "hidden",
                }}
            >
                <div
                    style={{
                        backgroundImage: `url("https://www.clubmahindra.com/blog/media/section_images/desktopban-1dd64ec81f39a29.webp")`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        zIndex: 1,
                    }}
                ></div>

                {/* Dark Overlay */}
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",  // Dark overlay to make text readable
                        zIndex: 2,
                    }}
                ></div>

                {/* Content */}
                <div
                    style={{
                        position: "relative",
                        zIndex: 3,
                        textAlign: "center",
                        color: "white",
                        padding: "4rem 1rem",
                    }}
                >
                    <h1 className="fw-bold display-4">Explore the World with Local Experts</h1>
                    <p className="lead">Discover unforgettable experiences with the best certified tour guides worldwide</p>

                    {/* Search Bar */}
                    <InputGroup className="my-4" style={{ maxWidth: '600px', margin: '0 auto' }}>
                        <InputGroup.Text className="bg-white">
                            <AiOutlineSearch />
                        </InputGroup.Text>
                        <FormControl
                            placeholder="Search for tour guides by name or location"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </InputGroup>
                </div>
            </div>

            {/* Features Section */}
            <Container className="my-5">
                <Row className="text-center">
                    <Col sm={12} md={4}>
                        <AiOutlineSafetyCertificate size={50} className="mb-3" />
                        <h4 className="fw-semibold">Certified Professionals</h4>
                        <p>All our guides are certified and experienced, ensuring a high-quality experience</p>
                    </Col>
                    <Col sm={12} md={4}>
                        <AiOutlineStar size={50} className="mb-3" />
                        <h4 className="fw-semibold">Highly Rated Guides</h4>
                        <p>Our guides have excellent ratings from past travelers, guaranteeing satisfaction</p>
                    </Col>
                    <Col sm={12} md={4}>
                        <AiOutlineGlobal size={50} className="mb-3" />
                        <h4 className="fw-semibold">Worldwide Availability</h4>
                        <p>Find expert guides in destinations around the world, from local hidden gems to famous landmarks</p>
                    </Col>
                </Row>
            </Container>



            <Container>
                <div className='my-5'>
                    <h2 className="text-left text-success mb-4">Embark on a Journey of Discovery</h2>
                    <h6 className="text-left text-secondary mb-4">
                        Uncover the world’s most beautiful places and find your perfect escape for an unforgettable adventure
                    </h6>
                </div>
                <BeautifulPlacesSlider />
            </Container>
            {/* <h2 className="text-left text-primary mb-4">Create Your Own Travel Adventure</h2>
            <h6 className="text-left text-secondary mb-4">
                Design personalized packages and connect with expert guides to bring your dream vacation to life.
            </h6> */}
            <Container className="d-flex justify-content-center align-items-center my-5">
                <Row>
                    <Col md={12}>
                        <Card className="shadow-lg border-0">
                            <Card.Img
                                variant="top"
                                src="https://www.rajasthantourplanner.com/images/Rajasthan-summer-package.jpg"
                                alt="Travel Package"
                                className="rounded-top"
                            />
                            <Card.Body className="text-center p-5">
                                <h2 className="mb-4">Create Your Perfect Travel Package</h2>
                                <p className="mb-4 text-muted">
                                    Craft and share unique travel experiences with ease! Add your favorite destinations, upload images, and set prices to start receiving bids from world-class guides.
                                </p>
                                <Link to={'/package'}>
                                    <Button variant="success" size="lg">
                                        Get Started
                                    </Button>
                                </Link>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <section className="packages-section py-5">
                <Container>
                    <h2 className="text-center mb-4">Current Posted Packages</h2>
                    <p className="text-center text-muted mb-4">
                        Discover amazing travel packages crafted by our community.
                    </p>
                    <Row>
                        {postedPackages.map((pkg) => (
                            <Col md={4} key={pkg.id} className="mb-4">
                                <Card className="shadow h-100">
                                    <Card.Img
                                        variant="top"
                                        src={pkg.image}
                                        alt={pkg.title}
                                        className="package-image"
                                    />
                                    <Card.Body>
                                        {/* User Information */}
                                        <div className="d-flex align-items-center mb-3">
                                            <Image
                                                src={pkg.user.avatar}
                                                roundedCircle
                                                alt={pkg.user.name}
                                                className="me-2"
                                                style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                                            />
                                            <div>
                                                <h6 className="mb-0">{pkg.user.name}</h6>
                                                <small className="text-muted">Posted by</small>
                                            </div>
                                        </div>

                                        {/* Package Details */}
                                        <Card.Title>{pkg.title}</Card.Title>
                                        <Card.Text>
                                            <strong>Destination:</strong> {pkg.destination}
                                            <br />
                                            <strong>Price:</strong> ${pkg.price}
                                            <br />
                                            <strong>Bids:</strong> {pkg.bids}
                                        </Card.Text>

                                        <Link to={`/packageSelect/${pkg.id}`}><Button variant="success" block>
                                            View Details
                                        </Button></Link>
                                    </Card.Body>
                                </Card>
                            </Col>

                        ))}
                    </Row>
                    <Button variant="success" className="rounded-pill">
                        View All Packages <FaArrowRight className="ms-2" />
                    </Button>
                </Container>
            </section>

            {/* Discover Guides Section */}
            <Container className="my-4">


                <h2 className="text-left text-success mb-4">Discover Your Dream Destination</h2>
                <h6 className="text-left text-secondary mb-4">
                    Explore breathtaking locations and find your perfect guide for an unforgettable journey.
                </h6>
                <TourSlider vales={filteredGuides} />

                {/* View All Guides Button */}

            </Container>
            {/* Testimonials Section */}





            <div className='my-5' >
                <Container>
                    <div style={{ boxSizing: 'border-box', border: '2px solid green', borderRadius: '20px ', padding: '20px' }}>
                        <Row className="d-flex justify-content-center">
                            <Col sm={12} md={6} lg={4}>
                                <Card className="shadow-lg border-0 p-4 m-2" style={{ backgroundColor: '#28a745', color: 'white', borderRadius: '12px', minHeight: '250px' }}>
                                    <Card.Body>
                                        <div className="d-flex align-items-center mb-3">
                                            <AiOutlineCheckCircle size={30} className="text-white me-3" />
                                            <h5 className="fw-bold">Authentic Local Experiences</h5>
                                        </div>
                                        <Card.Text style={{ textAlign: 'justify' }}>
                                            Get off the beaten path and explore the true essence of your destination with a local guide who will show you hidden gems, cultural landmarks, and provide insider tips.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={12} md={6} lg={4}>
                                <Card className="shadow-lg border-0 p-4 m-2" style={{ backgroundColor: '#28a745', color: 'white', borderRadius: '12px', minHeight: '270px' }}>
                                    <Card.Body>
                                        <div className="d-flex align-items-center mb-3">
                                            <AiOutlineHeart size={30} className="text-white me-3" />
                                            <h5 className="fw-bold">Personalized Tours</h5>
                                        </div>
                                        <Card.Text style={{ textAlign: 'justify' }}>
                                            Tailor your tour to fit your interests! Whether you’re an adventurer, foodie, or history buff, Withlocals offers personalized tours that cater to your unique preferences.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col sm={12} md={6} lg={4}>
                                <Card className="shadow-lg border-0 p-4 m-2" style={{ backgroundColor: '#28a745', color: 'white', borderRadius: '12px', minHeight: '270px' }}>
                                    <Card.Body>
                                        <div className="d-flex align-items-center mb-3">
                                            <AiOutlineSmile size={30} className="text-white me-3" />
                                            <h5 className="fw-bold">Memorable Interactions</h5>
                                        </div>
                                        <Card.Text style={{ textAlign: 'justify' }}>
                                            Enjoy meaningful, fun, and authentic interactions with locals who are eager to share their culture, traditions, and stories, making your travel experience more memorable.
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>


<div style={{ backgroundColor: "#f8f9fa", padding: "3rem 0"}}>
    <Container>
        <div className='my-3'>
            <h2 className="text-left text-success mb-4">What Our Travelers Have to Say</h2>
            <h6 className="text-left text-secondary mb-4">
                Discover breathtaking destinations and hear firsthand from those who've experienced the magic of traveling with us. Let their stories inspire your next unforgettable journey.
            </h6>
        </div>

        <Carousel>
            <Carousel.Item>
                <div className="testimonial text-center">
                    <p className="fs-4">"The best experience ever! Our guide was professional and truly knowledgeable."</p>
                    <footer>- John Doe</footer>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="testimonial text-center">
                    <p className="fs-4">"Amazing journey! The guide made everything so much more interesting and fun."</p>
                    <footer>- Jane Smith</footer>
                </div>
            </Carousel.Item>
            <Carousel.Item>
                <div className="testimonial text-center">
                    <p className="fs-4">"A once-in-a-lifetime experience. Highly recommended!"</p>
                    <footer>- Emily Adams</footer>
                </div>
            </Carousel.Item>
        </Carousel>

    </Container>
</div>

            {/* Footer */}
            <Footer />
        </div>
    );
};

export default HomeTour;

import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { FaSeedling, FaTasks, FaRegHandshake } from "react-icons/fa";
import { Link } from "react-router-dom";
import './styles/AgroTechHome.css'; // Add the CSS file for custom styling

function AgroTechHome() {
    return (
        <div className="d-flex align-items-center justify-content-center text-center">
            {/* Hero Section */}
            <div className="hero-overlay w-100">
                {/* Content above dark overlay */}
                <div className="content my-4">
                    <Row className="text-light">
                        <Col md={12}>
                            <div className="mt-4">
                                <FaSeedling
                                    style={{ fontSize: "4rem", color: "#28a745", marginBottom: "1rem" }}
                                />
                                <h1 className="display-4 mb-3">Welcome to AgroTech</h1>
                                <p className="lead mb-4">
                                    Empowering farmers with technology to optimize manufacturing scheduling and capacity planning. AgroTech helps you manage your agricultural business with ease and efficiency.
                                </p>
                                <Link to="/center-operations">
                                    <Button variant="success" size="lg">
                                        Get Started
                                    </Button>
                                </Link>
                            </div>
                        </Col>
                    </Row>
                </div>
                {/* Features Section */}
                <div className="container">
                    <Row className="features-section-home-agro">
                        <Col md={4} className="mb-4">
                            <div className="feature-box">
                                <FaTasks style={{ fontSize: "3rem", color: "#28a745" }} />
                                <h4>Efficient Scheduling</h4>
                                <p>
                                    Easily plan and schedule production, making sure you use your resources efficiently.
                                </p>
                            </div>
                        </Col>
                        <Col md={4} className="mb-4">
                            <div className="feature-box">
                                <FaRegHandshake style={{ fontSize: "3rem", color: "#28a745" }} />
                                <h4>Collaborative Tools</h4>
                                <p>
                                    Connect with other farmers and distributors to build a stronger, more effective network.
                                </p>
                            </div>
                        </Col>
                        <Col md={4} className="mb-4">
                            <div className="feature-box">
                                <FaSeedling style={{ fontSize: "3rem", color: "#28a745" }} />
                                <h4>Sustainable Growth</h4>
                                <p>
                                    Foster sustainable practices that help you grow more while protecting the environment.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default AgroTechHome;

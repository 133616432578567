// App.js
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Navigationbar from './component/Navigationbar';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Passreset from './pages/Passreset';
import Bomview from './pages/Bomview';
import { AuthProvider } from './Auth/AuthProvider';
import PrivateRoute from './Auth/PrivateRoute';
import DocApp from './DoctorApp/DocApp';
import HomeTour from './TourApp/HomeTour';
import AllGuides from './TourApp/pages/AllGuides';
import UserProfile from './TourApp/pages/UserProfile';
import PackageCreate from './TourApp/pages/PackageCreate';
import PackageSelect from './TourApp/pages/PackageSelect';
import AgroTechHome from './Agrotech/pages/AgroTechHome';
import CenterGroup from './Agrotech/pages/ResourceGroup';
import CenterGroupManagement from './Agrotech/pages/ResourceGroupManagement';
import RollingHome from './Rolling_Forecast/RollingHome';
import LodingData from './Rolling_Forecast/LodingData';
import CenterOperations from './Agrotech/pages/ResourceOperations';
import AgroTechMainPage from './Agrotech/pages/AgroTechMainPage';
import Resources from './Agrotech/pages/Resources';
import ResourceGroup from './Agrotech/pages/ResourceGroup';
import ManagementResourceGroup from './ResourcesManagement/pages/ManagementResourceGroup';
import ManagementResources from './ResourcesManagement/pages/ManagementResources';
import JoinMeeting from './DoctorApp/JoinMeeting';




function App() {
  return (
    <AuthProvider>
      <Router>
        <Navigationbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/docApp" element={<DocApp />} />
          <Route path="/register" element={<Register />} />
          <Route path="/password-reset" element={<Passreset />} />
          <Route path="/bom/:product_id" element={<PrivateRoute component={Bomview} />} />
          <Route path="/homeTour" element={<HomeTour />} />
          <Route path="/allGuides" element={<AllGuides/>} />
          <Route path="/userProfile/:id" element={<UserProfile/>} />
          <Route path="/package" element={<PackageCreate/>} />
          <Route path="/packageSelect/:packageId" element={<PackageSelect/>} />
          <Route path="/AgroTechHome" element={<AgroTechHome/>} />
          <Route path="/resourceGroupCreate" element={<ResourceGroup/>} />
          <Route path="/manageResourceGroup" element={<CenterGroupManagement/>} />
          <Route path="/center-operations" element={<CenterOperations/>} />
          <Route path="/rollingHme" element={<RollingHome/>}/>
          <Route path="/rollingLodingData" element={<LodingData/>}/>
          <Route path="/AgroTechMain" element={<AgroTechMainPage/>} />
          <Route path="/Resources" element={<ManagementResources/>} />
          <Route path="/ManagementResourceGroup" element={<ManagementResourceGroup/>} />
          <Route path="/joinMeeting/:token" element={<JoinMeeting/>} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;





